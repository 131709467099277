
// import Drill from '../Drill';
import { Link } from 'react-router-dom';
import { useLiveQuery } from "dexie-react-hooks";
// import { db } from '../config/db';
import { db, routineRepositoryDb } from 'src/config/db';
import { IDrill } from 'src/models/Drill';
import { useEffect, useState } from 'react';
import { PhysioItemView, SearchComponent } from './partials/PhysioItemView';
import { PhysioItem, physioKey } from 'src/models/Physio';
import { ShowStoreLinkIfTargetAudience } from 'src/utils/AppAdvert';
import { Helmet } from 'react-helmet-async';

export function DrillList() {
  const items = useLiveQuery(() => {
    const drills = db.drills.orderBy('lastAccessedAt').reverse().limit(20).toArray();
    const workouts = db.workouts.orderBy('lastAccessedAt').reverse().limit(20).toArray();
    return Promise.all([drills, workouts]).then(([drillResults, workoutResults]) => {
      const items = [...drillResults, ...workoutResults];
      items.sort((a, b) => ((b.lastAccessedAt?.getTime() || 0) - (a.lastAccessedAt?.getTime() || 0))); // Sort items by lastAccessedAt in descending order
      return items.slice(0, 20);
    });
  }, [], []);

  console.log("items", items)

  return (
    <div id="MainPage" className="common-page">
      <Helmet>
        <title>PhysioTimer.com - Your timers and drills</title>
      </Helmet>

      {items.length === 0 && <div className="first-time-intro">
        <p className="my-4">Welcome to PhysioTimer, your go-to free tool designed specifically for athletes, coaches, and anyone seeking a structured and effective approach to sports training and rehabilitation.</p>
        <p className="my-4">See <Link to='/agility-balance'>Perturbation Drills</Link> or <Link to='/about'>About</Link> pages for general infos. Below, you'll find some sample drills to kick-start your journey. Feel free to explore these or create your own drill and dive into using the app immediately.</p>
      </div>}

      <div className="title">
        <img src='/icons/icon.svg' alt="PhysioTimer logo"/>
        <h2>Timers and Drills</h2>
      </div>

      <div className="drill-list list-group">
        {items?.map((item) => <PhysioItemView item={item} key={physioKey(item)}/>)}

        {/* <div>
          <Link key='drill_new' to="drills/new" className="list-group-item list-group-item-action p-2">
            <h5 className="mb-1"><i className="bi-plus-circle-fill"></i> Create a new drill</h5>
            ...
            <span className="edit-link text-primary"><i className="bi-plus-circle-fill"></i></span>
          </Link>
        </div> */}
      </div>

      <SearchOrPromote items_avoid = {items} />

      <ShowStoreLinkIfTargetAudience />
    </div>
  );
}

const promoteMaxItems = 10;

function SearchOrPromote({items_avoid}: {items_avoid: PhysioItem[] | undefined}) {
  const [searching, setSearching] = useState<boolean>(false);

  const promoting = items_avoid === undefined || items_avoid.length < promoteMaxItems;

  const searchInput = (value: any, onChange: any) => {
    const searchText = promoting ? 'Check out the popular ones or search:' : 'Search some popular drills...';
    return (
      <div className="input-group">
        <input type="text" className='form-control' placeholder={searchText} value={value} onChange={onChange} />
        <div className="input-group-append">
          <span className="input-group-text"><i className="bi bi-search"></i></span>
        </div>
      </div>
    )
  }

  return (<>
    <div className="drill-list list-group mt-4">
      <div className="item  ">
        <Link key='drill_new' to="drills/new" className="list-group-item list-group-item-action p-2">
          <h5 className="mb-1"><i className="bi-plus-circle-fill"></i> Create a new drill or timer</h5>
          ...
          {/* <span className="edit-link text-primary"><i className="bi-plus-circle-fill"></i></span> */}
          {/* <span className="edit-link text-primary"><i className="bi-plus"></i></span> */}
        </Link>
      </div>

      <div className="mt-4">
        <SearchComponent searchInput={searchInput} setSearching={setSearching} />
      </div>
    </div>

    {!searching && promoting && <PromoteDrills items_avoid={items_avoid} /> }
  </>)
}

function PromoteDrills({items_avoid}: {items_avoid: PhysioItem[] | undefined}) {

  // const drills = useLiveQuery(() => {return routineRepositoryDb.drills.limit(20).toArray()});
  // const drills = routineRepositoryDb.drills.limit(20).toArray();

  const [items, setItems] = useState<IDrill[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const promote_ids = [11, 12, 13, 42, 41, 30, 20, 1010, 1020, 1030]
      let data = await routineRepositoryDb.drills.where('id').anyOf(promote_ids).toArray();

      data = data.filter((item) => !items_avoid?.some((drill) => drill.copiedFromId === item.id));

      setItems(data);
    };

    // run only once when drills_avoid is loaded
    if (items_avoid !== undefined && items_avoid.length < promoteMaxItems && items.length === 0){
      fetchData();
    }
  }, [items_avoid, items]);

  if (!items || items.length === 0) {
    // While loading show some min weight div so the scrolling wont jumps around.
    return <div style={{minHeight: '100vh'}}></div>;
  } else {
    return <>
      {/* <div className='form-control' style={{border: 'none'}}>Or select one of the premade ones:</div> */}
      <div className="drill-list list-group mt-4 pt-2">
        {items?.map((item) => <PhysioItemView item={item} key={physioKey(item)}/> )}
      </div>
    </>;
  }
}

// function ResetDbInfo() {
//   return (
//     <div className="mt-4">
//       <OverlayTrigger trigger="focus" placement="top" overlay={
//         <Popover>
//           <Popover.Header as="h3">This will destroy all changes.</Popover.Header>
//           <Popover.Body>
//             <button className="btn btn-danger" onClick={async (e) => { e.preventDefault(); await dbResetAll(); window.location.reload() }}>Delete all</button>
//           </Popover.Body>
//         </Popover>
//       }>
//         <button id="reset-db" className="btn btn-link">Reset all databases.</button>
//       </OverlayTrigger>
//       {/* // onClick={(e) => {e.preventDefault(); new Popover(document.querySelector('#reset-db') as Element) }} */}
//     </div>
//   )
// }
