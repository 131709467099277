import { Link } from "react-router-dom";

export default function PrivacyPage() {
  return (<>
    <h2>Privacy Policy</h2>

    <p>Contact us at <a href="mailto:support@physiotimer.com">support@physiotimer.com</a>.
       For various information see the <Link to='/about'>about-page</Link>.</p>

    <p>Our core principle is that you are in control of what
      personal information (if any) you choose to share through our service. We
      take privacy seriously.</p>

    <p>
      We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
    </p>

    <h2>Cookies</h2>

    <p>We collect non-personally-identifiable information about the visitors to
      the site in order to be able to manage the web site, track usage and improve
      the service. This non-personally-identifiable information may be shared with
      third parties to provide more relevant services and advertisements to
      visitors.</p>

    <p>We store information in "cookies". A cookie is a piece of data stored on
      the user's computer tied to information about the user. We use session ID
      cookies to store visitors' preferences and to record session information.  By
      default, we use a persistent cookie that stores your login ID (but not your
      password) to make it easier for you to login when you come back to our web
      site.</p>

    <p>Please note that we may allow 3rd party advertisers that are presenting
      advertisements on some of our pages to set and access their cookies on your
      computer, and that by using the service, you consent to the setting and
      accessing of such cookies on your computer. These cookies do not include any
      personal information you provide to us. Advertisers' use of cookies is
      subject to their own privacy policies, not this Privacy Policy. Specifically
      for EU users: we participate in the IAB Europe Transparency &amp; Consent
      Framework and comply with its Specifications and Policies. We use the Consent
      Management Platform with the identification number 300.</p>

    <h2>
      Your consent
    </h2>
    <p>
      By using our site, you consent to our websites privacy policy.
    </p>
  </>);
}

