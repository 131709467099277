
import { Outlet, RouteObject, ScrollRestoration, matchRoutes, useLocation, useNavigate } from "react-router-dom";
import FooterNav from "./partials/FooterNav";
import React from "react";
// import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { Analytics } from 'src/config/firebase';
import { LocalNotificationSetup } from "src/config/notifications";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import globals from "src/config/globals";
import { Device } from '@capacitor/device';
import { HelmetProvider } from "react-helmet-async";
import { LocalNotifications as CordovaLocalNotifications } from '@awesome-cordova-plugins/local-notifications/'; // https://github.com/powowbox/cordova-plugin-local-notification-12


export function CommonLayoutEffects({routes}: {routes: RouteObject[]}) {
  const location = useLocation();
  const navigate = useNavigate();


  // Iinitial setup of the app
  React.useEffect(() => {
    console.log("Initial Layout.useEffect. Setting up notifications and other once per session stuff")
    LocalNotificationSetup();

    Device.getLanguageCode().then((result) => {
      globals.languageCode = result.value;
      return Device.getLanguageTag();
    }).then((result) => {
      globals.languageTag = result.value;
      return Device.getId()
    }).then((result) => {
      globals.deviceId = result.identifier;
      Analytics.logCustomEvent({ name: 'DeviceLangInfo', event_category: `${globals.languageCode}_${globals.languageTag}`, event_label: 'null' });
    });


    // https://capacitorjs.com/docs/apis/app
    App.addListener('appStateChange', ({ isActive }) => {
      // onResume and onStop events
      // console.log('App state changed. Is active?', isActive);
      Analytics.logCustomEvent({name: 'Capacitor_App', event_category: 'appStateChange', event_label: `isAct:${isActive}_drill:${globals.drillRunning}`});
      CordovaLocalNotifications.clearAll();
    });
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      Analytics.logCustomEvent({name: 'Capacitor_App', event_category: 'appUrlOpen', event_label: globals.drillRunning});

      // Example url: https://physiotimer.com/tabs/tab2 // slug = /tabs/tab2
      const slug = event.url.split(".com").pop();
      if (slug) { navigate(slug); }
      // If no match, do nothing - let regular routing logic take over
    });
    App.addListener('appRestoredResult', data => {
      Analytics.logCustomEvent({name: 'Capacitor_App', event_category: 'appRestoredResult', event_label: globals.drillRunning});
    });
  // }, []);
  });

  // Page view tracking
  React.useEffect(() => {
    // console.log("Page view at tracking: ", location.key, location.pathname);

    const matchedRoutes = matchRoutes(routes, location);

    if (!matchedRoutes || matchedRoutes.length === 0) {
      console.log("No route matched");
      Analytics.logPageView({ page_title: `NoRouteMatched`, page_location: location.pathname });
    } else {
      let path = matchedRoutes[0].route.path;
      if (matchedRoutes.length > 1 && !path) {
        path = matchedRoutes[matchedRoutes.length - 1].route.path;
      }
      Analytics.logPageView({ page_title: `RouteMatched`, page_location: path || location.pathname });
    }
  }, [location, routes]);

  return (<>
    <HelmetProvider>
      <Outlet />
    </HelmetProvider>
    <ScrollRestoration />
  </>)
}

export function FooterNavLayout() {
  return (
    <>
      {/* all the other elements */}
      <div id="router-outlet" className="container layout-container">
        <Outlet />
      </div>

      <FooterNav fixed />
    </>
  );
}
