import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

// Could also create different environments and only then include this file
// https://github.com/facebook/create-react-app/discussions/9957

// console.log("Capacitor.getPlatform(): " + Capacitor.getPlatform());
if (Capacitor.getPlatform() === 'android') {
  console.log("Adding backbutton");

  App.addListener('backButton', ({ canGoBack }) => {
    console.log("BackButton pressed, canGoBack: " + canGoBack);
    if (canGoBack) {
      window.history.back();
    } else {
      App.exitApp();
    }
  });
}
