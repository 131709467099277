export class Prompt {

  id: string;
  htmlText: string;
  markdownText?: string;
  sound: string | null;
  style: {} | null;
  text: string | null;

  constructor(id: string, htmlText: string, sound: string | null = null, style: {} | null = null, text: string | null = null) {
    this.id = id;
    this.htmlText = htmlText;
    this.text = (text === null) ? htmlText : text;
    // this.sound = sound;
    this.sound = (sound === null) ? this.text : sound;
    this.style = style;
  }

  /////////////////////////////////////////////////////////
  // Static methods

  static prompts: Record<string, Prompt | string[]> = {};

  static getById(id: string): Prompt[] {
    let prompt = Prompt.prompts[id];
    if (Array.isArray(prompt)) {
      return this.getAllById(prompt);
    } else {
      return [prompt];
    }
  }

  static getAllById(ids: string[]): Prompt[] {
    let prompts: Prompt[] = [];
    for (let id of ids) {
      const prompt = Prompt.getById(id);
      if (Array.isArray(prompt)) {
        prompts.push(...prompt);
      } else {
        prompts.push(prompt);
      }
    }
    return prompts;
  }

  static getOrCreateByNames(names: string | string[]): Prompt[] {
    if (typeof names === 'string') {
      let p = Prompt.getById(names);
      return (p[0] == null) ? [new Prompt('', names, names)] : p;
    } else {
      return names.map((n) => this.getOrCreateByNames(n)).flat().filter(n => n);
    }
  }

}


// ////////////////////////////////////////////////////////////////////////////////////////////
// Basic Prompts
// Html arrow: https://www.compart.com/en/unicode/block/U+1F800 - Wide-Headed Leftwards Heavy Barb Arrow
// Make sure that last text component is some common element that can be used in notifications (https://www.w3schools.com/charsets/ref_utf_arrows.asp)
// id, htmlText, sound, style, text
[['left', '➜', 'left', { transform: 'rotate(180deg) translateY(-3vh)' }, '←'],
['front', '➜', 'front', { transform: 'rotate(270deg) translateY(-1vh)' }, '↑'],
['right', '➜', 'right', { transform: '' }, '→'],
['back', '➜', 'back', { transform: 'rotate(90deg) translateY(-1vh)' }, '↓'],
['center', '⊙', 'center'],
['front-right', '➜', 'front right', { transform: 'rotate(-45deg) translateY(-1vh)' }, '↗'],
['back-right', '➜', 'back right', { transform: 'rotate(45deg) translateY(-1vh)' }, '↘'],
['back-left', '➜', 'back left', { transform: 'rotate(135deg) translateY(-1vh)' }, '↙'],
['front-left', '➜', 'front left', { transform: 'rotate(225deg) translateY(-1vh)' }, '↖'],
].forEach((args: any[]) => {
  // const style = args[3] ? JSON.stringify(args[3]) : null;
  const style = args[3] ? args[3] : null;
  const text = args[4] ? args[4] : null;
  Prompt.prompts[args[0]] = new Prompt(args[0], args[1], args[2], style, text);
});
// ].map((args) => Prompt.add(args[0], ...args.slice(1)));

// ////////////////////////////////////////////////////////////////////////////////////////////
// Lists
[['arrows', 'front', 'right', 'back', 'left'],
['arrows-6', 'front', 'front-right', 'back-right', 'back', 'back-left', 'front-left'],
['left-right', 'left', 'right']
].forEach((args: string[]) => { Prompt.prompts[args[0]] = args.slice(1) })
// ].forEach((args) => { Prompt.addCollection(args[0], args.slice(1)) })

// console.log(Prompt.prompts);

// https://www.compart.com/en/unicode/block/U+1F800 - Wide-Headed Leftwards Heavy Barb Arrow
// const prompts = [['🢀', 'Left'], // ['➜🡺', 'Right'], ['🡹', 'Front'], ['🡻', 'Back']
// const prompts = [['left', '➜', 'left', {transform: 'rotate(180deg) translateY(-10px)'}],
//                  ['front', '➜', 'front', {transform: 'rotate(270deg) translateY(-10px)'}],
//                  ['right', '➜', 'right', {transform: ''}],
//                  ['back', '➜', 'back', {transform: 'rotate(90deg) translateY(-10px)'}],
//                  ['center', '⊙', 'center']
//                 ].map((args) => new Prompt(...args))


// window.Prompt = Prompt; // for debugging
// const promptsArrow = Prompt.getAllById(['left', 'front', 'right', 'back']);
