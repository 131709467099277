
import { db, routineRepositoryDb } from 'src/config/db';
import { ISequence, ISequenceCompact, Sequence } from 'src/models/Sequence';
import { loaderPreFilter } from 'src/utils/CommonUtils';

export interface IDrill {
  id?: number; // Primary key. Optional (autoincremented)
  copiedFromId?: string | number; // Original id from routineRepositoryDb
  name: string;
  info?: string;
  sequence: ISequence | ISequenceCompact;
  lastAccessedAt?: Date; // Timestamp for last accessed information
}

// Check all drills with copiedFromId and return the original one if found and its still the same
// export async function drillLoadOriginalFromDb(iDrill: IDrill, id: string | number): Promise<IDrill|null> {
export async function drillLoadOriginalFromDb(iDrill: IDrill): Promise<IDrill|null> {
  // const drillCopies = await db.drills.where('copiedFromId').equals(id).toArray();

  const drillCopies = await db.drills.where('name').equals(iDrill.name).toArray();
  const matchingDrills = drillCopies.filter(drillCopy => areDrillsEqual(iDrill, drillCopy));
  if (matchingDrills.length > 0) {
    const match = matchingDrills[0];
    match.lastAccessedAt = new Date();
    // db.drills.put(match); // Could be drills.update also
    db.drills.update(match.id!, {'lastAccessedAt': new Date()});
    return match;
  } else {
    return null;
  }
}


export async function drillRouteLoader({ request, params }: { request: any, params: { drillId: string|number } }) {
  await loaderPreFilter(request);

  const id = (typeof params.drillId === 'number') ? params.drillId : parseInt(params.drillId);
  if (Number.isNaN(id)) { throw new Response(`Not Found - Drill id should be number (${params.drillId})`, { status: 404 }); }

  // const drill = await drillLoadFromDbs(id);
  let drill = await db.drills.get(id);
  if (!drill) { // Not found from db, load from routineRepositoryDb
    drill = await routineRepositoryDb.drills.get(id);
    if (drill) { // Found from routineRepositoryDb, copy to db
      // let drillCopies = await db.drills.where('copiedFromId').equals(id).toArray();
      // let copyFound = false;
      // for (const drillCopy of drillCopies) {
      //   if (areDrillsEqual(drillCopy, drill)) {
      //     copyFound = true;
      //     drill = drillCopy;
      //   }
      // }
      // if (!copyFound) {
      //   drill.id = undefined;
      //   drill.copiedFromId = id;
      // }
      const drillOriginal = await drillLoadOriginalFromDb(drill);
      if (drillOriginal) {
        drill = drillOriginal;
      } else {
        drill.id = undefined;
        drill.copiedFromId = id;
      }
    }
  }

  if (drill) { // Update lastAccessedAt
    drill.lastAccessedAt = new Date();
    // db.drills.update(drill.id!, {'lastAccessedAtt': drill.lastAccessedAt});
    if (drill.id) {
      db.drills.put(drill); // Could be drills.update also
    } else {
      // Need to await so that the id is set before returning
      await db.drills.put(drill); // Has to be put because the drill might come from another db
    }
    return drill;
  } else {
    throw new Response(`Not Found - Drill id (${params.drillId})`, { status: 404 });
  }
}


export function areDrillsEqual(drill1: IDrill, drill2: IDrill): boolean {
  if (drill1.name !== drill2.name) {
    return false;
  }

  // Compare 'info' (considering undefined and null values as equal)
  if (drill1.info !== drill2.info) {
    if (drill1.info == null && drill2.info == null) {
      // Both are either undefined or null, so considered equal
    } else {
      // One is undefined/null and the other isn't, or they are different strings
      return false;
    }
  }

  // // Make sure that objects with different order of keys are still the same
  // function sortObject(obj: any): any {
  //   if (typeof obj !== 'object' || obj === null) { return obj; }

  //   if (Array.isArray(obj)) { return obj.map(sortObject); }

  //   // Sort objects and their nested objects
  //   const sortedKeys = Object.keys(obj).sort();
  //   const result: any = {};
  //   for (const key of sortedKeys) {
  //     result[key] = sortObject(obj[key]); // Recursively apply sorting
  //   }
  //   return result;
  // }
  // const jsonifySequence = (seq: ISequenceCompact|ISequence) => JSON.stringify(sortObject(Sequence.deserialize(seq).serialize()));
  // if (jsonifySequence(drill1.sequence) !== jsonifySequence(drill2.sequence)) {
  //   return false;
  // }
  if (!Sequence.areEqual(drill1.sequence, drill2.sequence)) {
    return false;
  }

  // All other properties are equal
  return true;
}


export function infoParser(info_str: string){

  // https://regexr.com/3dj5t
  // https://stackoverflow.com/questions/3717115/regular-expression-for-youtube-links
  // https://stackoverflow.com/a/31711517

  let text = info_str;
  let youtube = null;
  let youtube_time = null;

  // var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  // const regExp = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/;
  const regExp = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+).*(?:\s|$)/;
  var match = info_str.match(regExp);
  // console.log("Matchin youtube link: ", match)
  if (match && match[1]) {
    // console.log("Matchin youtube link: ", match, match[0])
    youtube = match[1];
    text = text.replace(match[0], '');

    // This would be faster to use the same regExp again but with a different capture group, but this is more readable
    const urlObj = new URL(match[0]);
    const params = new URLSearchParams(urlObj.search);
    youtube_time = params.get('t');
    // console.log("urlObj etc", urlObj, urlObj.search, params, params.get('t'));
  }

  const html = text;

  return {text: text, html: html, youtube: youtube, youtube_time: youtube_time};
}
