import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getAnalytics } from "@firebase/analytics";
// https://firebase.google.com/docs/web/setup#available-libraries

import { FirebaseAnalytics, LogEventOptions, SetCurrentScreenOptions } from '@capacitor-firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAjfLpg0q8VUAndtAbkgxYM8yPq1QNtlBo",
  authDomain: "physiotimer-db02a.firebaseapp.com",
  databaseURL: "https://physiotimer-db02a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "physiotimer-db02a",
  storageBucket: "physiotimer-db02a.appspot.com",
  messagingSenderId: "122046135008",
  appId: "1:122046135008:web:0bd8341faaa80bf1fdae85",
  measurementId: "G-ZXV4MTEHGZ"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const USE_ANALYTICS = (process.env.NODE_ENV === 'production') || false;

if (USE_ANALYTICS) {
  // initializeApp(firebaseConfig);

  // const analytics = getAnalytics(app);
  // logEvent(analytics, 'initialization');

  FirebaseAnalytics.setEnabled({ enabled: true, }).then(() => {
    return FirebaseAnalytics.setCurrentScreen({
      screenName: "Startup",
      screenClassOverride: "Initialization",
    });
  }).then(() => {
    FirebaseAnalytics.getAppInstanceId().then((result) => {
      console.log("Firebase Analytics app instance id", result.appInstanceId);
    }).catch((error) => {});
  });

}


export namespace Analytics {

  export function logPageView({ page_location, page_title }: { page_location: string; page_title: any }) {
    logEvent({ name: 'page_view', params: { page_location, page_title } });
  }

  export function logSearch({ search_term }: { search_term: string; }) {
    logEvent({ name: 'search', params: { search_term } });
  }

  // https://developers.google.com/tag-platform/gtagjs/reference/events#exception
  export function logException({ description, fatal }: { description: string; fatal: boolean; }) {
    logEvent({ name: 'exception', params: { description, fatal } });
  }

  // export function logNotification({ title, body }: { title: string; body: string; }) {
  //   logEvent({ name: 'notification', params: { title, body } });
  // }

  export function logCustomEvent({ name, event_category, event_label, value }: { name: string; event_category: string; event_label: undefined|string|boolean|number; value?: undefined|string|boolean|number; }) {
    logEvent({ name, params: { event_category, event_label, value } });
  }

  export function setCurrentScreen(options: SetCurrentScreenOptions) {
    if (process.env.NODE_ENV === 'development'){
      console.log("Analytics.setCurrentScreen", options.screenName, options.screenClassOverride);
    }

    if (USE_ANALYTICS) {
      FirebaseAnalytics.setCurrentScreen(options);
    }
  }

  // Grah GA, custom events would need custom metrics (for older than 30min data), try to use predefined events
  // https://stackoverflow.com/questions/66113452/view-event-parameters-from-events-older-than-30-minutes-in-google-analytics-202
  // https://support.google.com/analytics/answer/2709829#zippy=%2Cin-this-article
  function logEvent(options: LogEventOptions) {
    if (process.env.NODE_ENV === 'development'){
      console.log("Analytics.logEvent", options.name, options.params);
    }

    if (USE_ANALYTICS) {
      // https://github.com/capacitor-community/firebase-analytics/blob/master/src/web.ts#L159
      // https://github.com/capacitor-community/firebase-analytics/blob/master/android/src/main/java/com/getcapacitor/community/firebaseanalytics/FirebaseAnalytics.java
      FirebaseAnalytics.logEvent(options);
    }
  }
}



