import { db, routineRepositoryDb } from "src/config/db";
import { IDrill } from "./Drill";
import { loaderPreFilter } from "src/utils/CommonUtils";

export interface IWorkout {
  id?: number;
  copiedFromId?: number; // Original id from routineRepositoryDb
  name: string;
  info?: string;
  drills: IDrill[];
  lastAccessedAt?: Date; // Timestamp for last accessed information
}

export interface IWorkoutCompact extends Omit<IWorkout, 'lastAccessedAt'> {
// export interface IWorkoutCompact extends Omit<IWorkout, 'drills'|'lastAccessedAt'> {
//   drills: any[];
}

export class Workout {
  iworkout!: IWorkout;

  constructor(iworkout: IWorkout) {
    this.iworkout = iworkout;
  }

  get id() { return this.iworkout.id; }

  get drills() {
    this.iworkout.drills.forEach((drill, index) => {
      drill.id = index;
    });
    return this.iworkout.drills;
  }

  // save() {
  //   db.workouts.put(this.iWorkout);
  // }
}


export async function workoutRouteLoader({ request, params }: { request: any, params: { workoutId: string|number } }) {
  await loaderPreFilter(request);

  const id = (typeof params.workoutId === 'number') ? params.workoutId : parseInt(params.workoutId);
  if (Number.isNaN(id)) { throw new Response(`Not Found - Workout id should be number (${params.workoutId})`, { status: 404 }); }

  const iworkout = await workoutLoadFromDbs(id);

  if (iworkout) {
    return new Workout(iworkout);
  } else {
    throw new Response(`Not Found - Workout id (${params.workoutId})`, { status: 404 });
  }
}

export async function workoutLoadFromDbs(id: number): Promise<IWorkout | null> {
  let workout = await db.workouts.get(id);

  if (!workout) {
    // Load drill from routineRepositoryDb
    workout = await routineRepositoryDb.workouts.get(id);

    // Switched from handling drills here to handling those when they are saved to database.
    // if (workout){
    //   // Changing drills from routineRepository:id_name[] to db:IDrill[]
    //   workout.drills = await Promise.all(workout.drills.map(async (drill_id) => {
    //     const drill = await routineRepositoryDb.drills.get(parseInt(drill_id as any));
    //     if (drill === undefined) { console.log("Workout loaded", workout!.id, "but drill not found for", drill_id); }
    //     return drill;
    //   })) as IDrill[];
    // }
  }

  if (workout) {
    workout.drills.forEach((drill, index) => {
      drill.id = index;
    });

    workout.lastAccessedAt = new Date();

    // db.drills.update(drill.id!, {'lastAccessedAtt': drill.lastAccessedAt});
    db.workouts.put(workout); // Has to be put because the drill might come from another db
  }

  return workout || null;
}
