import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "src/config/db";
import { increment, serverTimestamp } from "firebase/firestore";
import { FirebaseFirestore } from '@capacitor-firebase/firestore';
import { Button } from "react-bootstrap";
import { Share } from '@capacitor/share';
import { IDrill, drillLoadOriginalFromDb } from "src/models/Drill";
import { Sequence } from "src/models/Sequence";
import hash from "object-hash";
import { Analytics } from 'src/config/firebase';
import globals from "src/config/globals";
import { useNavigate } from 'react-router-dom';
import { DrillShowComponent } from "./DrillShow";
import { Capacitor } from "@capacitor/core";

const itemsReference = '/items';


// export function ShareItemButton({item}: {item: PhysioItem}) {
export function ShareItemButton({item}: {item: IDrill}) {

  const [isLoading, setLoading] = useState(false);

  function share() {
    // setLoading(!isLoading);
    setLoading(true);

    const data = { ...item, viewCount: 0, } as any;
    delete data.lastAccessedAt; delete data.id;

    // data.sequence = Sequence.deserialize(data.sequence).serialize();
    data.sequence = Sequence.unifyWithSerialize(data.sequence);
    // https://github.com/puleos/object-hash
    // would be nice to ignore all empty values to ensure compatibility in the future
    data.hash = hash(data, {excludeKeys: (key) => { return ['copiedFromId', 'type', 'deviceId', 'version'].includes(key) }});
    // console.log("Hashed data: ", data.hash, data);

    data.version = 1;
    data.type = 'drill';
    data.deviceId = `v01:${hash(globals.deviceId)}`

    FirebaseFirestore.addDocument({
      reference: itemsReference,
      data: data
    }).then((result) => {
      console.log("ShareItemButton.share Document written with ID: ", result, result.reference);
      const id = result.reference.id;

      const share = {
        text: `PhysioTimer "${item.name}".`,
        url: `https://physiotimer.com/share/${id}`,
      }

      Share.canShare().then((canShare) => {
        console.log("ShareItem.canShare", canShare);
        Analytics.logCustomEvent({name: 'share', event_category: 'canShare', event_label: !!canShare});
        if (canShare.value) {
          Share.share(share);
        } else {
          navigator.clipboard.writeText(`https://physiotimer.com/share/${id}`);
          alert("Share link copied to clipboard. You can paste that to your friends. " + share['url'] + " ");
        }
        setLoading(false);
      });
    }).catch((error) => {
      console.error("ShareItemButton.share error: ", error);
      Analytics.logException({ description: `ShareItemButtom error: ${error}`, fatal: true });
      throw(error);
    });
  }

  return (
    <Button className="btn btn-secondary" onClick={() => {share()}}>
      {isLoading ? <span className="spinner-border spinner-border-sm micon"></span> : <i className="bi bi-share-fill micon"></i>}
      Share
    </Button>
  )
}

export function Open(){
  const { shareId } = useParams();
  const navigate = useNavigate();
  const [isError, setError] = useState(false);
  const hasNavigatedTimeRef = useRef(0); // Avoid React.StrictMode useEffect running twice (duplicated db)
  const [iDrill, setIDrill] = useState<IDrill>();

  useEffect(() => {
    console.log("Open shareId: " + shareId)

    function redirectIfNotWeb(id: any) {
      // On web not redirecting because we want to have SEO benefits
      if (Capacitor.getPlatform() !== 'web') { navigate(`/drills/${id}`) };
    }

    async function loadDrill() {
      if (hasNavigatedTimeRef.current && (new Date().getTime() - hasNavigatedTimeRef.current < 1000)){
        console.log("OpenShare preventing double load. hasNavigatedTimeRef.current", hasNavigatedTimeRef.current, new Date().getTime() - hasNavigatedTimeRef.current);
        return; // Prevent the effect logic if it has already run
      }
      hasNavigatedTimeRef.current = new Date().getTime(); // Set the time to prevent the effect logic if it has already run (React.StrictMode)

      try {
        const reference = itemsReference+'/'+shareId;
        const result = await FirebaseFirestore.getDocument({reference: reference});

        console.log("OpenShare found item", JSON.stringify(result))

        let { name, info, sequence } = result.snapshot.data!;
        // sequence = Sequence.deserialize(sequence).serialize();
        sequence = Sequence.unifyWithSerialize(sequence);
        const iDrill: IDrill = { name, info, sequence };

        const drillOriginal = await drillLoadOriginalFromDb(iDrill);
        if (drillOriginal) {
          console.log("OpenShare found existing drill.", drillOriginal.id)
          setIDrill(drillOriginal);
          redirectIfNotWeb(drillOriginal.id);
        } else {
          console.log("OpenShare no existing drill, update counters and create a new one.")

          // God damn Capistrano addition doesn't support increment(1), or does it?
          await FirebaseFirestore.updateDocument({ reference: reference, data: {viewCount: increment(1), lastAccessedAt: serverTimestamp()} });

          // Create a new drill from the shared data
          iDrill.copiedFromId = shareId;
          iDrill.lastAccessedAt = new Date();
          setIDrill(iDrill);
          db.drills.put(iDrill).then((id) => {
            console.log("OpenShare created new drill.", id)
            redirectIfNotWeb(id);
          })
        }
      } catch (error) {
        console.log("OpenShare error", JSON.stringify(error), error)
        Analytics.logException({ description: `OpenShare error: ${error}`, fatal: true });
        setError(true);
        // throw error;
      }
    }

    loadDrill();
  }, [shareId, navigate])


  return (<>
    {iDrill ? DrillShowComponent(iDrill) :

        <div id="OpenSharePage" className="common-page">
          {/* <h1>Trying to open</h1> */}
          <div className="title title-xl">
            <img src='/icons/icon.svg' alt="PhysioTimer logo"/>
            {/* <h2>Timers, Drills and Workouts</h2> */}
            <h1>PhysioTimer</h1>
          </div>

          {isError ? <div className="fs-5"><h3>Error loading or item not found.</h3> Check that you have internet connection and try again.</div> :
                    <h3>Loading drill <span className="spinner-border text-primary mx-2"></span></h3>}

        </div>

    }
  </>)
}

