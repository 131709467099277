
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { db } from 'src/config/db';
import { ISequence, Sequence } from "src/models/Sequence";
import 'src/stylesheets/DrillEdit.scss';
import { Prompt } from "src/models/Prompt";
import { IDrill } from 'src/models/Drill';
import { Helmet } from 'react-helmet-async';

export function DrillFormEdit() {

  const iDrill = useLoaderData() as IDrill;
  const navigate = useNavigate();

  function save(data: any) {
    db.drills.put(data).then((id) => {
      console.log("DrillFormEdit saved", id)
      navigate(`/drills/${id}`);
      // window.location.href = `/drills/${info}/edit`;
    })
  }

  function deleteDrill() {
    if (window.confirm("Are you sure you want to delete this drill?")){
      db.drills.delete(iDrill.id!).then((id) => {
        console.log("DrillFormEdit deleted", iDrill.id)
        navigate(`/`);
      })
    }
  }

  function buttons(isDirty: boolean) {
    return <>
      <Link id='link_back' to={`/drills/${iDrill.id}`} className={`btn ${isDirty ? 'btn-secondary' : 'btn-primary'}`}>Back to timer</Link>
      <button className="btn btn-secondary" onClick={(e) => { e.preventDefault(); navigate(`/drills/new`, {state: {copy: iDrill}}); }}>Copy</button>
      <button className="btn btn-secondary" onClick={(e) => { e.preventDefault(); deleteDrill() }}>Delete</button>
    </>
  }

  console.log("DrillFormEdit with", iDrill)
  return (<>
    <Helmet>
      <title>Edit "{iDrill.name}" - PhysioTimer.com</title>
      <meta name="robots" content="noindex"></meta>
    </Helmet>
    <DrillForm type='edit' drillValues={iDrill} save={save} buttons={buttons}/>
  </>)
}

// Because basic <drillEdit.DrillForm newForm={true} /> route didn't force react to re-render the component after e.g. saving
export function DrillFormNew() {
  const navigate = useNavigate();
  const location = useLocation();

  console.log("State is", location.state);

  const newFormDefaultValues = {
    name: "", info: "", sequence:
      { reps: 1, steps: [{ reps: 1, steps: { name: "Prepare", time: 5, color: 'gray' } }, { reps: 1, steps: { time: 10, color: 'blue', sound: 'beep' } }] } as ISequence
  }

  if (location.state && location.state.copy) {
    const copy = location.state.copy as IDrill;
    console.log("DrillFormNew copy", copy.name, copy.id)
    newFormDefaultValues.name = copy.name + " (copy)";
    newFormDefaultValues.info = copy.info || "";
    newFormDefaultValues.sequence = sequenceRemoveExtra(copy.sequence);
  }

  function save(data: any) {
    db.drills.put(data).then((id) => {
      console.log("DrillFormNew creating", id)
      navigate(`/drills/${id}`);
    })
  }

  return (<>
    <Helmet>
      <title>Create a new drill - PhysioTimer.com</title>
    </Helmet>
    <DrillForm type='new' drillValues={newFormDefaultValues} save={save} />
  </>)
}


///////////////////////////////////////////////////////////////////////////////////////////

// export function DrillForm({ drillValues, newForm = false }) {
export function DrillForm({ type, drillValues, save, buttons }: { type: 'new'|'edit'|'workout', drillValues: any, save: any, buttons?: any }) {

  const useFormRet = useForm({ defaultValues: defaultValuesEnsureAllValuesAndToStrings(drillValues) })

  // console.log("useForm.values", useFormRet.getValues(), "errors", useFormRet.formState.errors);

  function onSubmit(event: any){
    console.log("onSubmit", event);

    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    // setValidated(true);
    if (form.checkValidity() === false) {
      // event.preventDefault();
      // event.stopPropagation();
      return false;
    } else {
      console.log("Before handlesbumit");
      useFormRet.handleSubmit(async (data: any) => {
        console.log("Handling submit", data);
        document.getElementById("submit")?.classList.add("disabled");
        document.querySelector("#submit span")?.classList.remove("d-none");

        data.sequence = sequenceToDb(data.sequence);

        // await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate slow submit
        save(data);

        // db.drills.put(data).then((info) => {
        //   console.log("Saved", info)
        //   window.location.href = `/drills/${info}/edit`;
        //   // if (newForm) {
        //   //   window.location.href = `/drills/${info}/edit`;
        //   // }
        // });
      })();
    }
  }

  return (
    <div className="drill-edit">
      <div className="d-none">{JSON.stringify(useFormRet.getValues())}</div>
      <Form className='mb-3 drill-form' onSubmit={onSubmit}>  {/* onChange={onChange}  */}
        <Form.Group className="input-group-lg mb-3">
          <Form.Control {...useFormRet.register("name")} required maxLength={80} placeholder="Drill name" style={{ fontSize: "2rem" }} />
        </Form.Group>

        <SequenceForm name='sequence' removeUp={null} {...{ useFormRet }} />

        <Form.Group className="mb-3">
          <Form.Control as='textarea' rows={5} {...useFormRet.register("info")} maxLength={10000} placeholder="Drill info: Guide, equipment needed and other possible notes." />
        </Form.Group>

        <div id='buttons' className="btn-group-lg">
          <button id='submit' type="submit" value="Save" className={`btn ${useFormRet.formState.isDirty ? 'btn-primary' : 'btn-secondary'}`}
                  onClick={(e) => {(e.target as Element).closest('form')!.classList.add('was-validated') }}>
            <span className="pe-2 d-none"><span className="spinner-border spinner-border-sm"></span></span>
            Save
          </button>
          <button id='reset' className={`btn  ${useFormRet.formState.isDirty ? 'btn-warning' : 'btn-secondary'}`} onClick={(e) => { e.preventDefault(); window.location.reload() }}>Reset</button>
          {buttons ? buttons(useFormRet.formState.isDirty) : ''}
        </div>
      </Form>
    </div>
  )
}

function SequenceForm({ name, removeUp, useFormRet }: { name: string, removeUp: any, useFormRet: UseFormReturn }) {
  // { name: string, control: Control, register: any, getValues: any, removeUp: any, reset: any }) {
  // console.log("SeqTest", name, getValues(name));
  const { fields, remove, append, replace } = useFieldArray({
    control: useFormRet.control, name: name + ".steps",
  });

  let fieldsInfo;
  let appendStep;
  const newSeqDefaults = { reps: 1, steps: { time: 10 } }
  if ('time' in useFormRet.getValues(name + ".steps")) {
    // console.log("Rendering step form fields in single one", fields);
    fieldsInfo = <StepForm key={name + "_step_form"} name={name} {...{ useFormRet }} />
    appendStep = () => {
      // replaceUp([{reps: 1, steps: getValues(name+".steps")}, {reps: 1, steps: {name: ''}}]);
      replace([{ reps: 1, steps: useFormRet.getValues(name + ".steps") }, newSeqDefaults]);
    }
  } else {
    const removeStep = (index: number) => {
      remove(index);
      let vals = useFormRet.getValues();

      vals.sequence = sequenceRemoveExtra(vals.sequence);
      useFormRet.setValue('sequence', vals.sequence, { shouldTouch: true });
      // useFormRet.reset(vals);
    }
    fieldsInfo = fields.map((item, index) => {
      return (
        <SequenceForm key={`${name}.steps[${index}]_sf`} name={`${name}.steps[${index}]`}
          removeUp={() => { removeStep(index) }} {...{ useFormRet }} />
      )
    })

    appendStep = () => { append(newSeqDefaults) }
  }

  return (
    <Form.Group className='mb-3 seq-form' style={{ border: "1px solid gray" }}>
      <Form.Group as={Row} className="reps mb-3">
        <Form.Label column className='col-auto'>Repetitions</Form.Label>

        <Col className="col col-auto"><Form.Control {...useFormRet.register(`${name}.reps`)} required type="number" step='1' min='0' max='1000'/></Col>
        {/* <Col className="col-4 col-form-label">{seq.render()}</Col> */}
        <i className="bi bi-node-plus-fill col-auto" style={{ fontSize: "1.5rem" }} onClick={appendStep}></i>
        {removeUp ? <i className="bi bi-trash col-auto" style={{ fontSize: "1.5rem" }} onClick={removeUp}></i> : ''}
      </Form.Group>

      {fieldsInfo}
    </Form.Group>
  );
}

function StepForm({ name, useFormRet }: { name: string, useFormRet: UseFormReturn }) {

  const addColor = (name_org: string, color: string) => {
    // let inp = (document.getElementsByName(name + ".steps.color")[0] as HTMLInputElement)
    let name = name_org + ".steps.color";

    let inp = useFormRet.getValues(name);
    if (inp === '') {
      useFormRet.setValue(name, color, { shouldDirty: true });
    } else {
      // inp.value = inp.value + "," + color;
      useFormRet.setValue(name, inp + ", " + color, { shouldTouch: true });
    }
    // onChange(null);
  }

  const changePrompt = (event: any, name_org: string, key: string) => {
    event.preventDefault();
    let name = name_org + ".steps.prompts";
    // document.getElementsByName(name + '.steps.prompts')[0] as HTMLInputElement).value = key
    useFormRet.setValue(name, key, { shouldDirty: true });
    // onChange(event);
  }

  let istep = useFormRet.getValues(name);
  return (
    <Row className="mb-3 step-form">
      {istep.name}
      {/* <Col className="col-3 col-md-1 col-lg-1"><Form.Control placeholder="Reps" {...useFormRet.register(name + '.reps')} style={{ width: "4rem" }} /></Col> */}
      <Col className="col-6 col-md-4 col-lg-2"><Form.Control placeholder="Name" {...useFormRet.register(name + '.steps.name')} /></Col>
      <Col className="col-6 col-md-4 col-lg-auto">
        <Form.Group className="input-group">
          {/* <Form.Control type="text" placeholder="Time" defaultValue={(Number.isInteger(istep.time)) ? (istep.time + ".0") : (istep.time.toString())} style={{width: "4rem"}}/> */}
          <Form.Control placeholder="Time" {...useFormRet.register(name + '.steps.time')} style={{ width: "5rem" }} type='number' step="0.1" min="0" />
          <div className="input-group-prepend">
            <span className="input-group-text">Sec</span>
          </div>
        </Form.Group></Col>
      <Col className="col-6 col-md-4 col-lg-3"><div className="input-group">

        <Form.Control placeholder="Prompt same as name" {...useFormRet.register(name + '.steps.prompts')} />
        <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
        <ul className="dropdown-menu dropdown-menu-end">
          <li key={name + '.prompt.info'} className='prompt-dropdown-info'><span className="dropdown-item">
            List of prompts which are shown randomly. E.g. <i>"jump, down, rest"</i>. Write your own or use following symbols or lists.</span></li>
          <div className="dropdown-divider"></div>
          {Object.keys(Prompt.prompts).filter((key: string) => (Prompt.getById(key).length === 1)).map((key: any) =>
            <li key={name + '.prompt.' + key}><a className="dropdown-item" href="#{key}" onClick={(e) => { changePrompt(e, name, key) }}>{key} - {Prompt.getById(key)[0].text}</a></li>
          )}
          <div className="dropdown-divider"></div>
          {Object.keys(Prompt.prompts).filter((key: string) => (Prompt.getById(key).length > 1)).map((key: any) =>
            <li key={name + '.prompt.' + key}><a className="dropdown-item" href="#{key}" onClick={(e) => { changePrompt(e, name, key) }}>{key} - {Prompt.getById(key).map((p) => p.text)}</a></li>
          )}
        </ul>

      </div></Col>
      {/* <Col className="col-6 col-md-4 col-lg-2"><Form.Control placeholder="No colors" {...register(name+'.steps.color')}  /></Col> */}
      <Col className="col-6 col-md-4 col-lg-2"><div className="input-group">
        <Form.Control placeholder="No colors" {...useFormRet.register(name + '.steps.color')} />
        <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
        <ul className="dropdown-menu dropdown-menu-end">
          {['gray', 'white', 'red', 'orange', 'yellow', 'green', 'blue', 'purple', 'pink', 'brown', 'black'].map((color) =>
            <li key={name + '.color.' + color}><a className="dropdown-item" href="#{color}" style={{ backgroundColor: color }} onClick={(e) => { e.preventDefault(); addColor(name, color) }}>{color}</a></li>
          )}
        </ul>
      </div></Col>
      <Col className="col-6 col-md-4 col-lg-2">
        <Form.Select {...useFormRet.register(name + '.steps.sound')} >
          <option value="">No sound</option>
          <option value="voice">Voice</option>
          <option value="beep">Beep</option>
        </Form.Select>
      </Col>
    </Row>
  )
}

export function sequenceToDb(seq: ISequence): ISequence {
  // if ('time' in seq.steps) { // step
  if (!Array.isArray(seq.steps)) { // step
    if ('color' in seq.steps && typeof seq.steps.color === 'string') {
      seq.steps.color = seq.steps.color.split(',').map((color) => color.trim()).filter(n => n);
      if (seq.steps.color.length === 0) { delete seq.steps.color; }
    }
    if ('prompts' in seq.steps && typeof seq.steps.prompts === 'string') {
      seq.steps.prompts = seq.steps.prompts.split(',').map((prompt) => prompt.trim()).filter(n => n);
      if (seq.steps.prompts.length === 0) { delete seq.steps.prompts; }
    }
  } else { // list of sequences
    seq.steps = seq.steps.map((step: any) => sequenceToDb(step));
  }
  return seq;
}

// Removes unnecessary {reps: 1, steps: {reps:1 ...}} or empty steps from sequence
export function sequenceRemoveExtra(seq_base: any): ISequence {

  const _iter = (seq: any): any => {
    if (seq === null || seq === undefined) { return null;
    } else if ('steps' in seq) { // It's iseq
      if (seq.steps === null || seq.steps === undefined) {
        changed = true;
        return null;
      } else if ('steps' in seq.steps) { // If you have iseqs in steps return only single one (so steq.steps.steps)
        changed = true;
        let reps = Math.max(seq.reps, seq.steps.reps);
        return { reps: reps, steps: _iter(seq.steps.steps) }
      } else if (Array.isArray(seq.steps)) {
        if (seq.steps.length === 0) {
          changed = true;
          return null;
        } else if (seq.steps.length === 1) { // If you have array in steps but its single one
          changed = true;
          let reps = Math.max(seq.reps, (seq.steps[0].reps ? seq.steps[0].reps : 1));
          return { reps: reps, steps: _iter(seq.steps[0]) }
        } else { // If you have array in steps and its multiple
          return { reps: seq.reps, steps: seq.steps.filter((n: any) => n).map((step: any) => _iter(step)) };
        }
      } else { // It's {reps: n, steps: IStep}, run through once to check if it's empty
        return { reps: seq.reps, steps: _iter(seq.steps) };
      }
    } else {
      if (Array.isArray(seq)) { // Array of iseqs
        return seq
      } else { // IStep
        if (seq.name || seq.time) { return seq }
        else { changed = true; return null }
      }
    }
  }

  let changes_i = 0;
  let changed = true;
  while (changed && changes_i < 10) {
    changed = false;
    seq_base = _iter(seq_base);
    changes_i++;
  }
  // if (seq_base.steps.length === 0) {
  //   return newFormDefaultValues.sequence;
  // }
  return seq_base as ISequence;
}

// Form.isDirty works only if defaultValues are exactly same, so we have to convert everything to string and make sure that all fields are present
function defaultValuesEnsureAllValuesAndToStrings(defaultValues: any) {
  defaultValues.info = defaultValues.info || "";

  defaultValues.sequence = sequenceRemoveExtra(defaultValues.sequence);
  defaultValues.sequence = Sequence.unifyWithSerialize(defaultValues.sequence);

  function seqExtend(seq: any) {
    if ('steps' in seq) { // It's iseq
      seq.reps = seq.reps.toString();
      if (Array.isArray(seq.steps)) {
        seq.steps = seq.steps.map((step: any) => seqExtend(step));
      } else {
        seq.steps = seqExtend(seq.steps);
      }
      return seq;
    } else {
      const defaults = { "name": '', "time": '', "color": '', "prompts": '', "sound": '' }
      const ret = { ...defaults, ...seq };
      Object.keys(defaults).forEach(key => { ret[key] = ret[key] ? ret[key].toString() : ''; });
      return ret;
    }
  }
  defaultValues.sequence = seqExtend(defaultValues.sequence);

  // console.log("defaultValuesExtendAll", defaultValues)

  return defaultValues
}

