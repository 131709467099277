import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

export default function Page() {
  return (
    <div className="common-page seo-page">
      <Helmet>
        <title>About PhysioTimer</title>
      </Helmet>

      <div className="title">
        <img src='/icons/icon.svg' alt="PhysioTimer logo"/>
        <h2>About PhysioTimer</h2>
      </div>

      <div className="row">
        <div className="col-xl-7">
          <div className="section mt-2">
            <h2>Your Ultimate Sports and Physiotherapy Timer Solution</h2>

            <p>Welcome to PhysioTimer, your premier free tool designed for athletes, coaches, and anyone in pursuit of a structured, effective approach to sports training and rehabilitation. Whether you're on the path to recovery from an injury, aiming to prevent future injuries, or looking to boost your athletic performance, our app is tailored to meet your specific needs.</p>
          </div>
          <div className="section">
            <h2>Who Can Benefit?</h2>

            <ul>
              <li><strong>Athletes</strong>: Boost your agility, strength, and endurance with precision-targeted drills.</li>
              <li><strong>Injury</strong> Recovery: Fast-track your rehabilitation with exercises specifically crafted for recovery.</li>
              <li><strong>Fitness</strong> Enthusiasts: Take your regular workout routines to the next level with our challenging and varied drills.</li>
              <li><strong>Coaches</strong>: Effortlessly create and share customized drills tailored to your trainees' needs.</li>
              <li><strong>Physiotherapists</strong>: Employ perturbation drills to effectively train patients' balance and recovery.</li>
            </ul>

          </div>
          <div className="section">
            <h2>Key Features</h2>

            <p>
              <strong>Customizable Timers</strong>: At the heart of our app lies a sophisticated timer system, enabling you to craft timers suited for diverse workout requirements. From short, intense intervals and structured warm-ups to longer recovery periods and high-effort plyometric sessions, our app guarantees precise timing for your training needs.
            </p>

            <p>
              <strong><Link to='/agility-balance'>Perturbation Drills</Link></strong>: Beyond customizable timers, our Perturbation Drills introduce randomized elements to dramatically improve your reaction skills and adaptability. Perfect for dynamic sports settings, these drills mimic real-life scenarios and challenges, offering a deeply immersive training experience.
            </p>

            <p>
              <strong>Drill Sharing</strong>: Effortlessly create and share your drills with friends, teammates, and trainees. There's no need for them to install the app — simply clicking on a link opens the timer directly. Our app facilitates the easy export of your drills and timers, making sharing your training routines with others seamless and convenient.
            </p>
          </div>
        </div>
        <div className="col section">
          <iframe src="/balance_demo/index.html" title="Balance demo" className="balance-demo"></iframe>
          <p>
            Example of a balance drill enhanced with the app's randomized elements for a more dynamic experience. <Link to="/drills/42/Single%20Leg%20Mini%20Squat%20With%20Balance%20%26%20Random%20Direction%20Tap">Try the drill</Link> or learn more about our <Link to='/agility-balance'>Agility and Balance drills</Link>.
          </p>
        </div>
      </div>
      <div>
        <div className="section">
          <h2>Additional Information</h2>

          <p>Consult our <Link to='/privacy'>privacy policy</Link> for legal details.</p>
          <p>For feedback and development suggestions, please reach out to <a href='mailto:support@physiotimer.com'>support@physiotimer.com</a>.</p>
          <p>Return to the <Link to='/'>main page</Link> to explore drills and timers.</p>
        </div>
      </div>
    </div>
  );
}

