import { Link } from "react-router-dom";
import { Container, Navbar } from 'react-bootstrap';
import { DrillSoundUtilVolumeIcon } from "src/models/DrillSoundUtil";

export default function FooterNav({ fixed = false }: { fixed?: boolean }) {
  // https://getbootstrap.com/docs/5.0/components/navbar/
  return (
    <Navbar fixed={fixed ? 'bottom' : undefined} className='bg-light navbar-footer'>
      <Container>
        <Link to={`/`} className='navbar-brand'>PhysioTimer</Link>
        {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0"> */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to='/about' className='nav-link'>About</Link>
          </li>
          <li className="nav-item">
            <Link to='/drills/new' className='nav-link'>New drill</Link>
          </li>
        </ul>

        {/* <ul className="navbar-nav ms-auto mb-2 mb-lg-0"> */}
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            {/* <Link to='/' className='nav-link'><i className="bi bi-volume-up-fill volume"></i></Link> */}
            {DrillSoundUtilVolumeIcon()}
          </li>
        </ul>


      </Container>
    </Navbar>
  );
}

