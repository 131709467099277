// import { Sequence } from './models/Sequence';

import 'src/stylesheets/Workout.scss';

import { Link, useRouteLoaderData } from 'react-router-dom';
import { IDrill } from 'src/models/Drill';
import MarkdownWithGfm from 'src/utils/MarkdownWithGfm';
import { Workout } from 'src/models/Workout';
import { DrillItem } from './partials/PhysioItemView';
import { physioKey } from 'src/models/Physio';

export function WorkoutShow(){
  // const workout = useLoaderData() as IWorkout;
  const workout = useRouteLoaderData('workout_element') as Workout;

  return (
    <div id="workout-show">
      <h1>{workout.iworkout.name}</h1>

      <div className="info my-3">
        <MarkdownWithGfm>{workout.iworkout.info}</MarkdownWithGfm>
      </div>

      <div className="drill-list list-group">

        <div className="list-group-item list-group-item-action p-2">
          <Link to={`/workouts/${workout.id}/drills/0`} className="start-button btn btn-success">
            <i className="bi bi-play-fill"></i>
          </Link>
        </div>

        {workout.drills?.map((drill: IDrill) => <DrillItem drill={drill} link_relative={`/workouts/${workout.id}/`} key={physioKey(drill)}/> )}

        <div>
          <Link key='drill_new' to="drills/new" className="list-group-item list-group-item-action p-2">
            <h5 className="mb-1">Add a new drill</h5>
            ...
          </Link>
        </div>
      </div>

    </div>
  )
}
