
import 'src/config/capacitor_android';
import 'src/config/firebase';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './stylesheets/index.scss';
import { CommonLayoutEffects, FooterNavLayout } from './pages/Layout';
import * as drillShow from './pages/DrillShow';
import * as mainPage from './pages/MainPage';
import * as drillEdit from './pages/DrillEdit';

import { WorkoutElement, WorkoutElementContainer } from './pages/WorkoutElement';

import * as workoutShow from './pages/WorkoutShow';

import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, ScrollRestoration } from "react-router-dom";
import { Capacitor } from '@capacitor/core';

// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'src/stylesheets/bootstrap_custom.scss';
// import "bootstrap";
// import * as bootstrap from 'bootstrap';
// window.bootstrap = bootstrap;

import { drillRouteLoader } from './models/Drill';
import { workoutRouteLoader } from './models/Workout';
import ErrorPage from './pages/ErrorPage';
import * as workoutDrillEdit from './pages/WorkoutDrillEdit';
// import * as searchPage from './pages/SearchPage';
import * as shareComponent from './pages/ShareComponent';
// import * as privacyPage from './pages/infos/PrivacyPage';
import PrivacyPage from './pages/infos/PrivacyPage';
import AboutPage from './pages/infos/AboutPage';
import AgilityBalancePage from './pages/infos/AgilityBalancePage';


console.log("Loading app index.js, (env:", process.env.NODE_ENV, ", platform:", Capacitor.getPlatform(), ', isNative:', Capacitor.isNativePlatform(), ')');

const routes = [
    { // No layout for this one
      path: "/drills/:drillId/:name?",
      element: <drillShow.DrillShow />,
      name: 'joohereisname',
      // loader: async ({ params }) => { return await db.drills.get(parseInt(params.drillId)); }
      loader: drillRouteLoader,
    },
    {
      element: <FooterNavLayout />,
      children: [
        {
          path: "/",
          element: <mainPage.DrillList />,
        },
        // {
        //   path: "/search",
        //   element: <searchPage.SearchPage />, // Use the imported component
        // },
        { path: "/privacy", element: <PrivacyPage />, },
        { path: "/about", element: <AboutPage />, },
        { path: "/agility-balance", element: <AgilityBalancePage />, },
        {
          path: "/drills/:drillId/edit",
          element: <drillEdit.DrillFormEdit />,
          loader: drillRouteLoader,
        },
        {
          path: "/drills/new",
          element: <drillEdit.DrillFormNew />,
        },
        {
          path: "/share/:shareId",
          element: <shareComponent.Open />,
        }
      ],
    },
    {
      element: <WorkoutElement />,
      path: "/workouts/:workoutId/:name?",
      id: 'workout_element',
      // loader: async ({ params }) => { const workout = await loadWorkout(parseInt(params.workoutId));
      //                                 if (!workout) { throw new Response(`Not Found - Workout id (${params.workoutId})`, { status: 404 }); }
      //                                 return workout },
      loader: workoutRouteLoader,

      children: [
        {
          path: "drills/:drillId/:name?",
          element: <drillShow.WorkoutDrillShow />,
        },
        {
          element: <WorkoutElementContainer />,
          children: [
            {
              path: "",
              element: <workoutShow.WorkoutShow />,
            },
            {
              path: "drills/:drillId/edit",
              element: <workoutDrillEdit.Edit />,
            },
            {
              path: "drills/new",
              element: <workoutDrillEdit.New />, // Use the imported component
            },
          ],
        }
      ]
    },
];

const routes_wrapper = createBrowserRouter([{
    errorElement: <ErrorPage />,
    element: <CommonLayoutEffects routes={routes} />,
    children: routes }],
  {
    // Base URL for the app, change also in package.json homepage if changing this.
    basename: (window.location.pathname.startsWith('/physiotimer') ? "/physiotimer" : ""),
  });

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={routes_wrapper} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
