// import { Sequence } from './models/Sequence';
import { Sequence } from '../models/Sequence';

import 'src/stylesheets/DrillShow.scss';

import { Capacitor } from '@capacitor/core';
import NoSleep from 'nosleep.js'; // https://github.com/richtr/NoSleep.js
import React from 'react';
import { Button, ButtonGroup, ProgressBar } from 'react-bootstrap';
import { Link, useLoaderData, useParams, useRouteLoaderData } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
// import { db } from 'src/config/db';
// import { LocalNotifications } from '@capacitor/local-notifications';
import { LocalNotifications as CordovaLocalNotifications } from '@awesome-cordova-plugins/local-notifications/'; // https://github.com/powowbox/cordova-plugin-local-notification-12
import drillSoundUtil from '../models/DrillSoundUtil';
import { TextToSpeech } from '@capacitor-community/text-to-speech';
import FooterNav from './partials/FooterNav';
import { Step } from 'src/models/Step';
import * as Drill from 'src/models/Drill';
import { IDrill } from 'src/models/Drill';
// import * as WorkoutElement from './WorkoutElement';
import { Workout } from 'src/models/Workout';
import MarkdownWithGfm from 'src/utils/MarkdownWithGfm';
import { Analytics } from 'src/config/firebase';
import globals from 'src/config/globals';
import { ForegroundService } from '@capawesome-team/capacitor-android-foreground-service';
import { ShareItemButton } from 'src/pages/ShareComponent';
import { ShowStoreLinkIfTargetAudience } from 'src/utils/AppAdvert';
import { Helmet } from 'react-helmet-async';


type DrillState = {
  step: number,
  timer: number,
  running: boolean, // add the running property to the state type
  timer_last: number,
  sequence: Sequence,
  notification_last: null|string,
  current: Step,
  flashPage: boolean,
}

// class DrillShowWithoutRouter extends React.Component<{params?: {id: any}}, DrillState> {
  // { type, drillValues, save }: { type: 'new'|'edit'|'workout', drillValues: any, save: any }) {
class DrillTimerComponent extends React.Component<{ children?: any, iDrill?: IDrill, workout?: Workout, workoutDrillId?: number, router?: any }, DrillState> {
  timerStep: number = 100;
  audio_context: null = null;
  noSleep: NoSleep;
  // drillSoundUtil: DrillSoundUtil;
  endSoundTimeout: null | NodeJS.Timeout = null;
  interval: null | NodeJS.Timeout = null;
  speechSynthesisUtterance: null | SpeechSynthesisUtterance = null;

  // constructor({ iDrill }: { iDrill: IDrill }) {
  constructor(props: any) {
    super(props);
    // this.audio_context = null;
    // this.timerStep = 100;
    let seq = Sequence.dummy()

    // const iDrill = props.iDrill;
    // console.log("DrillShow constructor", props.iDrill );

    this.state = {
      step: 0,
      timer: 0,
      running: false,
      // running: current.time ? false : true,
      timer_last: 0,
      // sequence: seq,
      sequence: seq,
      notification_last: null,
      current: seq.getStep(0),
      flashPage: false,
    };

    this.noSleep = new NoSleep();
    // this.state.current = this.state.sequence.getStep(0);
    // this.drillSoundUtil = new DrillSoundUtil();
    // this.drillSoundUtil = DrillSoundUtil;
    // this.endSoundTimeout = null;

    //   let newState = { id: id, name: idrill.name, info: idrill.info, sequence: seq, current: seq.getStep(0), running: false }
    //   if (!newState.current.time) { newState.running = true }
    //   this.setState(newState);
    //   this.notificationsRemove();
  }

  updateStateFromProps(first_mounting=false) {
    // console.log("DrillShow updateStateFromProps componentDid(Mount|Update)", this.props.workoutDrillId, this.props.iDrill );
    // LocalNotificationSetup();
    this.notificationsRemove();

    const iDrill = this.props.iDrill!;
    document.title = iDrill.name;
    let seq = new Sequence(iDrill.sequence);

    const init_state = {
      step: 0,
      timer: 0,
      timer_last: 0,
      sequence: seq,
      notification_last: null,
      running: false, // running: current.time ? false : true,
      flashPage: this.isWorkoutDrill(),
    }

    console.log("DrillShow updateStateFromProps", this.props.workoutDrillId, this.props.iDrill, this.props.router?.location?.state);

    if (this.props?.router?.location?.state?.rest_first && !first_mounting) {
      const rest_first = this.props.router.location.state.rest_first;
      console.log("Router state informed that should rest first for ", rest_first);

      // this.props.router.navigate(this.props.router.location.pathname, { replace: true });
      // this causes rerendering, so we are using info about componentDidMount for

      rest_first.prompt.sound = `${rest_first.prompt.sound}. Drill ended.`;
      const real_step = seq.unraveled[0];
      rest_first.prompt.markdownText = `${rest_first.prompt.htmlText}. Drill ended. '_${real_step.name}_' starting in`

      this.setState({
        ...init_state,
        step: -1,
        current: rest_first,
      }, () => { this.notificationsRemove(); this.start() });
    } else {
      const current = seq.getStep(0);
      this.setState({
        ...init_state,
        current: current,
      })
    }

    if (this.isWorkoutDrill()) {
      setTimeout(() => { this.setState({flashPage: false}) }, 1000);
    }
    globals.drillRunning = false; // for analytics/crashes etc
  }

  async componentDidMount() {
    // console.log("DrillShow componentDidMount", this.props.workoutDrillId, this.props.iDrill);
    this.updateStateFromProps(true);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // console.log("ComponentDidUpdate called with", this.props)
    if (this.props.iDrill !== prevProps.iDrill || this.props.workoutDrillId !== prevProps.workoutDrillId) {
      // console.log("ComponentDidUpdate updateStateFromProps")
      this.updateStateFromProps();
    }
  }

  componentWillUnmount() {
    this.pause();
    this.notificationsRemove();
  }

  notificationsRemove() {
    if (Capacitor.getPlatform() === 'android') {
      // return LocalNotifications.removeDeliveredNotifications();
      CordovaLocalNotifications.clearAll();
      this.setState({ notification_last: null })
    }
  }

  notificationUpdate() {
    if (this.state.running) {
      if (Capacitor.getPlatform() === 'android') {
        let title = "" + this.state.current.istep.name;
        if (this.state.current.prompt && this.state.current.prompt.text && title !== this.state.current.prompt.text) {
          title += ": " + this.state.current.prompt.text;
        }
        title += title ? ' (' + this.timeLeftStr() + ')': this.timeLeftStr();

        if (this.state.notification_last !== title) {
          console.log("Notification update", title, this.state.notification_last)
          // console.log("Notification defaults", JSON.stringify(CordovaLocalNotifications.getDefaults()));
          // {"actions":[],"alarmVolume":-1,"attachments":[],"autoLaunch":false,"autoClear":true,"badge":null,"channelName":null,"clock":true,"color":null,"data":null,"defaults":0,"foreground":null,"group":null,"groupSummary":false,"icon":null,"iconType":null,"id":0,"launch":true,"led":true,"lockscreen":true,"mediaSession":null,"number":0,"priority":0,"progressBar":false,"resetDelay":5,"silent":false,"smallIcon":"res://icon","sound":true,"sticky":false,"summary":null,"text":"","timeoutAfter":false,"title":"","trigger":{"type":"calendar"},"vibrate":false,"wakeup":true,"channelId":null,"wakeLockTimeout":null,"fullScreenIntent":false,"triggerInApp":false}
          // console.log("LocalNotification update", title)
          // https://github.com/katzer/cordova-plugin-local-notifications

          const progressBar = this.state.current.time ? { value: this.state.timer, maxValue: this.state.current.time * 1000 * .9} : false;
          CordovaLocalNotifications.schedule({
            title: title, progressBar: progressBar,
            id: 1, autoClear: false, foreground: false, clock: false, sound: undefined, wakeup: false,
            smallIcon: 'res://ic_logo'
            // color: this.state.current.color, // umm led color? no need
            // id: 1, autoClear: false, foreground: false, clock: false, sound: '', wakeup: false,
            // icon: 'res://logo' // this shows the logo as img in notification, not at the top
          });
          this.setState({ notification_last: title });

        }
      }
    } else {
      this.notificationsRemove();
    }
  }

  // The timer should save start time + milliseconds, so that when browser is closed, it can be resumed.
  // https://www.youtube.com/watch?v=MCi6AZMkxcU - https://gist.github.com/jakearchibald/cb03f15670817001b1157e62a076fe95
  // Other relevant if you want to change for functional react component.
  // https://github.com/amrlabib/react-timer-hook#readme - https://felixgerschau.com/react-hooks-settimeout/
  // https://www.smashingmagazine.com/2021/05/get-started-whac-a-mole-react-game/
  setTimer(reset = true) {
    clearTimeout(this.interval as NodeJS.Timeout);
    if (this.state.running && this.state.current.time) {
      let elapsed = performance.now() - this.state.timer_last;
      let timer = this.state.timer + elapsed;
      let durationInMs = this.state.current.time * 1000;
      let timeLeft = durationInMs - timer;
      // console.log("Drill setTimer: ", reset, timer < durationInMs, Math.floor(timer), Math.floor(elapsed), 'endsoundtimeout', this.endSoundTimeout)

      if (reset || timer < durationInMs) {
        // this.setState((state) => ({ timer: (reset ? 0 : timer), timer_last: performance.now() }));
        this.setState({ timer: (reset ? 0 : timer), timer_last: performance.now() });
        this.notificationUpdate();

        // For last 3 seconds play beeps every 1 second, if istep.sound === 'beep'
        if (!this.endSoundTimeout && timeLeft < 3500 && timeLeft > 1000 && timer > 1500 && this.nextStep() && this.nextStep().istep.sound === 'beep') {
          this.endSoundTimeout = setTimeout(() => { drillSoundUtil.customBeep({ duration: 200, frequency: 600 }); this.endSoundTimeout = null }, timeLeft % 1000);
        }

        // This wont work because the prompt is initialized when we call step.reset which we call when we call in initStep
        // For fixing you would have to save state if reset has been done, do it here and then avoid initStep doing it again
        // // Start voice sound a bit before next step so it's more in sync with screen
        // if (!this.endSoundTimeout && timeLeft < 500 && this.nextStep() && this.nextStep().istep.sound === 'voice') {
        //   console.log("STarting sound earlier", this.state.current.prompt, this.nextStep().prompt)
        //   this.endSoundTimeout = setTimeout(() => { this.sound(this.nextStep()) }, 100);
        // }

        let step = (this.timerStep < timeLeft) ? this.timerStep : timeLeft;
        this.interval = setTimeout(() => { this.setTimer(false) }, step);
      } else {
        this.proceedToNextStep();
        // this.notificationUpdate();
      }
    } else {
      this.notificationsRemove();
    }
  }

  pause() {
    clearTimeout(this.endSoundTimeout as NodeJS.Timeout);
    this.endSoundTimeout = null;
    drillSoundUtil.stop();

    clearTimeout(this.interval as NodeJS.Timeout);
    this.setState({ running: false }, () => { this.notificationsRemove(); setTimeout(() => { this.notificationsRemove() }, 50); });
    if (Capacitor.getPlatform() === 'android') {
      console.log("ForegroundService stopForegroundService");
      ForegroundService.stopForegroundService();
    } else {
      // if (Capacitor.getPlatform() === 'web') {
    }
    this.noSleep.disable();
    // Analytics.logEvent({name: 'drill_pause', params: {id: this.props.iDrill!.id}});
    Analytics.logCustomEvent({name: 'drill_action', event_category: 'pause', event_label: this.props.iDrill!.id});
    globals.drillRunning = false; // for analytics/crashes etc
  }

  start() {
    if(this.state.step === this.state.sequence.length-1 && (!this.state.current.time || this.state.timer === this.state.current.time * 1000) ) {
      // If finished and continuing, start from beginning
      return this.initStep({step: 0, callback: () => {this.start()}})
    }

    drillSoundUtil.start();
    this.setState({ running: true, timer_last: performance.now() }, () => { this.setTimer(false) });
    if (this.state.timer === 0){ this.sound(); }

    if (Capacitor.getPlatform() === 'android') {
      console.log("ForegroundService removeAllListeners.then")

      // ForegroundService.checkPermissions().then((result) => {
      ForegroundService.requestPermissions().then((result) => {
        console.log("ForegroundService checkPermissions", result, JSON.stringify(result), result.display)

        // ForegroundService.requestManageOverlayPermission().then((result) => {
        //   console.log("ForegroundService checkManageOverlayPermissions", result, JSON.stringify(result))
        // });

        // Maybe check if timer is more than 3 min longer, only then start the foreground thingie
        ForegroundService.startForegroundService({body: "PhysioTimer", id: 1, title: 'starting drill ...', smallIcon: 'ic_logo'})
      }).then(() => {
          this.notificationUpdate();
      });

      // ForegroundService.removeAllListeners().then(() => {
      //   console.log("ForegroundService removeAllListeners.then starting adding")
        // ForegroundService.addListener('buttonClicked', event => {
        //   console.log("ForegroundService buttonClicked", event)
        //   ForegroundService.stopForegroundService();
        //   ForegroundService.moveToForeground();
        // });
      //   console.log("ForegroundService starting service")
      //   // other notification should override this one
      //   ForegroundService.startForegroundService({body: "PhysioTimer", id: 1, title: 'starting drill ...', smallIcon: 'ic_logo'}).then(() => {
      //     notificationUpdate();
      //   });
      // });

    } else {
      // if (Capacitor.getPlatform() === 'web') { // on web we want to keep screen on because otherwise timeouts might not work
    }
    this.noSleep.enable(); // For all platforms, its stops screen going off while drill is running.

    // Analytics.logEvent({name: 'drill_start', params: {id: this.props.iDrill!.id}});
    Analytics.logCustomEvent({name: 'drill_action', event_category: 'start', event_label: this.props.iDrill!.id});
    globals.drillRunning = true; // for analytics/crashes etc
  }

  sound(step?: Step|undefined) {
    if (drillSoundUtil.getVolume() === 0){
      return false;
    }
    if (!step) {
      console.log("Nostep, using current")
      step = this.state.current;
    }

    let sound = step.istep.sound
    // let sound = this.state.current.istep.sound;
    console.log("Current sound", step, sound, (step.prompt && step.prompt.sound))

    let prompt_sound = step.prompt && step.prompt.sound;
    if (sound === 'voice' && prompt_sound) {
      // https://capacitorjs.com/docs/apis/screen-reader would be capacitor option, but then there wouldn't be rate option.
      if (Capacitor.getPlatform() === 'android') {
        // Webview not supporting https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesisUtterance
        // so using https://github.com/capacitor-community/text-to-speech
        TextToSpeech.stop().then(() => {
          return TextToSpeech.speak({
            text: prompt_sound as string,
            lang: 'en-US', rate: 1.3, pitch: 1.0, volume: drillSoundUtil.getVolume()*0.99, // weirdly worked better with 0.99
            // lang: 'en-US', rate: 1.3, pitch: 1.0, volume: 0.05,
            category: 'playback', // IOS only, should work on background
          })}).catch((reason) => { console.error("TextToSpeech error", reason) });

      } else {
        let synth = window.speechSynthesis;
        synth.cancel();

        // https://stackoverflow.com/questions/54861046/why-does-a-speechsynthesisutterance-sometimes-not-fire-an-end-event-in-chromiu
        // Sometimes this might have been carbage colleted before sounding, so we need to keep it in class.
        // let utterThis = new SpeechSynthesisUtterance(prompt_sound);
        this.speechSynthesisUtterance = new SpeechSynthesisUtterance(prompt_sound);
        this.speechSynthesisUtterance.rate = 1.3; // 1.5
        this.speechSynthesisUtterance.volume = drillSoundUtil.getVolume();
        // utterThis.onend = function (event) { console.log('SpeechSynthesisUtterance.onend'); }
        this.speechSynthesisUtterance.onerror = (event) => { console.error('SpeechSynthesisUtterance.onerror', event); }
        synth.speak(this.speechSynthesisUtterance);
      }
    } else if (sound === 'beep') {
      drillSoundUtil.customBeep({ duration: 600, frequency: 800 });
    }
  }

  proceedToNextStep() {
    const isWorkoutAndNotLastDrill = this.isWorkoutDrill() && this.props.workoutDrillId! < this.props.workout!.drills.length-1
    console.log("proceedToNextStep", isWorkoutAndNotLastDrill, this.state.step, this.state.sequence.length-2, 'resting', this.nextStep()?.isRest(), this.nextStep())
    if (this.state.step === this.state.sequence.length-1) {
      // Finished everything
      if (this.state.current.time) {
        this.setState({ timer: this.state.current.time * 1000 });
      }
      this.pause();

      if (isWorkoutAndNotLastDrill) {
        console.log("Workout Drill ended, navigating to next one")
        this.props.router.navigate(`../drills/${this.props.workoutDrillId!+1}/`);
      }
    } else if (isWorkoutAndNotLastDrill && this.state.step === this.state.sequence.length-2 && this.nextStep()?.isRest()) {
      console.log("Workout Drill only rest remaining, navigating to next one")
      this.initStep({step: this.state.step + 1, running: false, sound: false, callback: () => {
        this.props.router.navigate(`../drills/${this.props.workoutDrillId!+1}/`, {state: {rest_first: this.state.current}});
      }});
    } else {
      // Normal next step
      console.log("Drill proceedToNextStep: ", this.state.step, this.state.current.name, this.state.current.prompt);
      this.initStep({step: this.state.step + 1, sound: true});
      // this.initStep({step: this.state.step + 1, sound: !this.endSoundTimeout});
    }
  }

  initStep({step, sound=false, running=true, callback=null}: {step: number, sound?: boolean, running?: boolean, callback?:null|(() => void | null)}) {
    this.setState((state) => {
      clearTimeout(this.interval as NodeJS.Timeout);
      clearTimeout(this.endSoundTimeout as NodeJS.Timeout);
      this.endSoundTimeout = null;
      step = Math.max(Math.min(step, this.state.sequence.length - 1), 0);
      let seq = state.sequence.getStep(step);
      return {
        running: running,
        step: step,
        timer: 0,
        current: seq
      }
    }, () => {
      this.setTimer();
      if (sound) {
        this.sound()
      }
      if (callback) {
        callback();
      }
    })
  }

  nextStep() {
    return this.state.sequence.unraveled[this.state.step + 1];
  }

  timeLeftStr() { // for displaying 00:10 etc
    if (this.state.current.time) {
      let s = (this.state.current.time - this.state.timer/1000);
      let m = Math.floor(s / 60);
      s = Math.floor((s - m * 60) + 0.99);

      if (s === 60) { s = 0; m += 1; } // Added back because we show 1 sec more than actual time
      // console.log("Drill timeLeftStr: ", this.state.current.time, this.state.timer, m, s)
      // https://stackoverflow.com/questions/3733227/javascript-seconds-to-minutes-and-seconds
      return ''+(9<m?'':'0')+m+(9<s?':':':0')+s;
    } else {
      return ''
    }
  }

  promptTextDiv() {
    if (!this.state.current.prompt || !this.state.current.prompt.htmlText) {
      return null;
    }
    const prompt = this.state.current.prompt;
    let t_length = prompt.htmlText.length

    let text = null;

    if (this.state.current.isRest() && this.state.step > 0 && this.state.step < this.state.sequence.length-1) {
      text = <span>{prompt.htmlText}.<p/><i>'{this.nextStep()!.name}'</i> starting in</span>;
      t_length = prompt.htmlText.length + this.nextStep()!.name.length + "   starting in".length
    } else if (prompt.markdownText) {
      text = <MarkdownWithGfm>{prompt.markdownText}</MarkdownWithGfm>
      t_length = prompt.markdownText.length
    } else if (prompt.style) {
      text = <span style={prompt.style as React.CSSProperties}>{prompt.htmlText}</span>
    } else {
      text = prompt.htmlText
    }

    const textLenghtClass = "length_" + (t_length <= 3 ? '3' : (t_length <= 6 ? '6' : (t_length <= 9 ? '9' : (t_length <= 20 ? 'long' : 'long_paragraph'))))

    return (
      <div><div className={'text ' + textLenghtClass}>
        {!this.state.current.time ? <div className="action spinner-grow"></div> : null}
        {text}
        {/* {this.state.current.prompt.style ?
          <div style={this.state.current.prompt.style as React.CSSProperties}>{this.state.current.prompt.htmlText}</div> :
          this.state.current.prompt.htmlText } */}
      </div></div>
    )
  }
  promptTimed() {
    return (
      <>
        <div className='prompt prompt-timed'>
          {this.promptTextDiv()}
          <div><div className="clock">{this.timeLeftStr()}</div></div>
        </div>
        {this.state.running ? null : <div className="not-running-play-button"><Button className='btn-success' onClick={() => { this.start() }}><i className="bi bi-play-fill"></i></Button></div>}
      </>
    )
  }
  promptStatic() {
    let nextStepName = '';
    if (this.nextStep()) { // this.state.step < this.state.sequence.length
      let nextStep = this.nextStep()!;
      nextStepName = nextStep.name;
      if (nextStep.time) { nextStepName += ' (' + nextStep.time + ' sec)'; }
    }
    return (
      <div className='prompt prompt-static'>
        { this.promptTextDiv() }
        { nextStepName ?
          <div className="next-step">
            <Button onClick={() => { this.initStep({step: this.state.step + 1}) }}>
              {/* <i className="bi bi-arrow-right-circle-fill"></i> */}
              <i className="bi bi-skip-forward-fill"></i>
              { nextStepName }
            </Button>
          </div>
          : null }
      </div>
    )
  }

  isWorkoutDrill() {
    return this.props.workoutDrillId !== undefined
  }

  // nameEditLink() {
  //   return (
  //     <h2>
  //       {(this.props.workoutDrillId !== undefined ? (this.props.workoutDrillId+1) + '. ' : '')} {this.props.iDrill!.name} {' '}
  //       <Link to={`../drills/${this.props.workoutDrillId !== undefined ? this.props.workoutDrillId : this.props.iDrill!.id}/edit`} className="btn btn-secondary"><i className="bi bi-gear-fill"></i> Edit</Link>
  //     </h2>
  //   )
  // }

  render() {

    // console.log("Drill rendering ", this.state.current, this.state.currentPrompt);

    // const listItems_org = this.state.sequence.unraveled.map((seq, i) =>
    //   i === this.state.step ? <strong key='currentStepInfo'>{seq.name}</strong> : seq.name
    // ).flatMap((seq) => [seq, ' -> ']).slice(0, -1)

    const listItemsRendered = (this.state.step > 0 || this.state.timer > 0) ? this.state.sequence.render(this.state.step) : this.state.sequence.render(-1)
    let listItems = reactStringReplace(listItemsRendered.replaceAll(' / ', '/'), /__(.+)__/g, (match, i) => (<strong key="currentStepInfo">{match}</strong>));

    let showInfo = (this.state.step === 0 && this.state.timer === 0) || (this.isWorkoutDrill() && this.state.step === -1); // && !this.state.running;

    return (
      // <div id='drill' className={(showInfo ? 'init-info' : 'running') + ((this.state.step > 0 || this.state.timer > 500) ? ' solid' : ' ') + (this.isWorkoutDrill() ? ' workout-drill': '') + (this.state.flashPage ? ' flash' : '')}>
      <div id='drill' className={(showInfo ? 'init-info' : 'running') + (this.isWorkoutDrill() ? ' workout-drill': '') + (this.state.flashPage ? ' flash' : '')}>
        <div className='timer'>
          <div className='top'>
            <div className='header mx-1'>
              {this.props.children}
              {/* {this.isWorkoutDrill() ? this.nameEditLink() : ''} */}
              {/* <span>({this.state.step + 1}/{this.state.sequence.length}): {listItems};</span> */}
              <span>{listItems}</span>
            </div>
            <div className='progress-bars'>
              <ProgressBar className='progress-bar-steps' variant='secondary'
                now={this.state.step} max={this.state.sequence.length - 1} />
              { this.state.current.time ?
                      <ProgressBar className={(this.state.running && this.state.timer > 10) ? 'progress-bar-running' : 'progress-bar-steps'}
                        now={this.state.timer + ((this.state.running  && this.state.timer > 10) ? this.timerStep/2 : 0)} max={this.state.current.time * 1000}
                      /> :
                      <ProgressBar className='progress-bar progress-bar-striped progress-bar-animated bg-secondary' />
              }
            </div>
          </div>

          <div className='prompt-container' style={{ backgroundColor: this.state.current.color }}>
            {this.state.current.time ? this.promptTimed() : this.promptStatic()}
            {/* <div className='prompt'>
              {this.promptTextDiv()}
              <div><div className="clock">{this.timeLeftStr()}</div></div>
            </div>
            {this.state.running ? null : <div className="not-running-play-button"><Button className='btn-success' onClick={() => { this.start() }}><i className="bi bi-play-fill"></i></Button></div>} */}
          </div>

          <div className="bottom-buttons">
            <ButtonGroup className="d-flex" size='lg'>
              { (this.props.workout && this.props.workoutDrillId! > 0 && this.state.step <= 0) ?
                <Link className='btn btn-secondary' to={`../drills/${this.props.workoutDrillId!-1}/`}><i className="bi bi-skip-backward"></i></Link> :
                <Button variant="secondary" disabled={(this.state.step === 0) ? true : undefined} onClick={() => { this.initStep({step: this.state.step - 1}) }}><i className="bi bi-skip-backward-fill"></i></Button>
              }

              {this.state.current.time ?
                  this.state.running
                    ? <Button variant="warning" onClick={() => { this.pause() }}><i className="bi bi-pause-fill"></i></Button>
                    : <Button variant="success" onClick={() => { this.start() }}><i className="bi bi-play-fill"></i></Button>
                  : null}
              <Button variant="danger" className={this.state.current.time ? '' : 'only-stop'} disabled={(this.state.step === 0 && this.state.timer === 0) ? true : undefined} onClick={() => { this.pause(); this.initStep({step: 0, running: false}) }}><i className="bi bi-stop-fill"></i></Button>

              { (this.props.workout && this.props.workoutDrillId! < this.props.workout.drills.length-1 && this.state.step === this.state.sequence.length-1 ) ?
                <Link className='btn btn-secondary' to={`../drills/${this.props.workoutDrillId!+1}/`}><i className="bi bi-skip-forward"></i></Link> :
                <Button variant="secondary" disabled={(this.state.step === this.state.sequence.length-1) ? true : undefined} onClick={() => { this.initStep({step: this.state.step + 1}) }}><i className="bi bi-skip-forward-fill"></i></Button>
              }
            </ButtonGroup>
          </div>

          {/* <div className="info-arrow"><i className="bi bi-caret-down-fill"/></div> */}
        </div>
        <div className="info-arrow"><i className="bi bi-caret-down-fill"/></div>
      </div>
    )
  }
}


function InfoContent({info_str} : {info_str: string | undefined | null}) {
  if (info_str === null || info_str === undefined || info_str === '') {
    return null;
  }
  const info = Drill.infoParser(info_str)

  if (info.youtube) {
    console.log("Adding youtube for", info.youtube)
  }

  return (<>
      <div className="content mb-4">
        <MarkdownWithGfm>{info.html}</MarkdownWithGfm>
      </div>

      {info.youtube ? <iframe title="Youtube video" src={`https://www.youtube-nocookie.com/embed/${info.youtube}${info.youtube_time ? `?start=${info.youtube_time}` : ''}`} allow="encrypted-media" allowFullScreen></iframe> : ''}
      {/* <iframe title="Youtube video" src="https://www.youtube-nocookie.com/embed/vyvse9EtOH8" allow="encrypted-media" allowFullScreen></iframe> */}
    </>
  )
}


export function DrillShowComponent(iDrill: IDrill) {
  let canUrl = '';
  if (iDrill.copiedFromId) {
    canUrl = `/drills/${iDrill.copiedFromId}`
    if (/[a-zA-Z]/.test(String(iDrill.copiedFromId))) {
      canUrl = `/share/${iDrill.copiedFromId}`;
    }
  }
  return (
    <div id="drill-show-outer">
      <Helmet>
        <title>{iDrill.name} - PhysioTimer.com</title>
        {canUrl && <link rel="canonical" href={canUrl} />}
      </Helmet>

      <DrillTimerComponent iDrill={iDrill} />

      <div className="bottom-container container">
        <h2 className="mb-4">
          {iDrill.name}
          <Link to={`/drills/${iDrill.id}/edit`} className="btn btn-secondary mx-2"><i className="bi bi-gear-fill micon"></i> Edit</Link>
          <ShareItemButton item={iDrill}/>
        </h2>

        <InfoContent info_str={iDrill.info} />
      </div>


      <ShowStoreLinkIfTargetAudience className='container' />
      <FooterNav />
    </div>
  )
}

export function DrillShow() {
  const iDrill = useLoaderData() as IDrill;
  return DrillShowComponent(iDrill);
}


export function WorkoutDrillShow() {
  const workout = useRouteLoaderData('workout_element') as Workout;
  const params = useParams<{ drillId: string }>();

  const navigate = useNavigate();
  const location = useLocation();

  const drillId = parseInt(params.drillId || '', 10);
  const iDrill = workout.drills![drillId];

  console.log("WorkoutDrillShow", params, iDrill)

  return (

    <div id="drill-show-outer">
      <DrillTimerComponent iDrill={iDrill} workout={workout} workoutDrillId={drillId} router={{ navigate, location }}>
        <h2>
          {iDrill.id!+1}. {iDrill.name} {' '}
          <Link to={`../drills/${iDrill.id}/edit`} className="btn btn-secondary"><i className="bi bi-gear-fill micon"></i> Edit</Link>
        </h2>
      </DrillTimerComponent>

      <div className="bottom-container container">
        <div className='alert alert-primary workout-drill-list'>
          {drillId === 0 ?
            <Link to={`..`}><i className="bi bi-skip-start-fill"></i><span>Workout</span></Link> :
            <Link to={`../drills/${drillId-1}/`}><i className="bi bi-skip-backward-fill"></i> <span>{drillId-1}. {workout.drills![drillId-1].name}</span></Link>
          }
          {drillId === workout.drills!.length-1 ?
            <Link to={`..`}><span>Workout Finished</span> <i className="bi bi-skip-end-fill"></i></Link> :
            <Link to={`../drills/${drillId+1}/`}><span>{drillId+1}. {workout.drills![drillId+1].name}</span> <i className="bi bi-skip-forward-fill"></i></Link>
          }
        </div>

        <InfoContent info_str={iDrill.info} />
      </div>

      <FooterNav />
    </div>
  )
}




