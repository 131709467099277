import { Capacitor } from "@capacitor/core";

export function ShowStoreLinkIfTargetAudience({className}: {className?: string}) {
  // if (Capacitor.getPlatform() !== 'web' || !/Android/i.test(navigator.userAgent)) {
  if (Capacitor.getPlatform() !== 'web' || /iPhone/i.test(navigator.userAgent)) {
    return null;
  }

  return (
    <div id="AppAdvert" className={`my-4 ${className}`}>
      <span>Timers and notifications work better if you install the app from Play Store.</span><br/>
      <a href='https://play.google.com/store/apps/details?id=com.physiotimer.app'
        className="d-inline-block my-2"><img alt='Get it on Google Play' style={{width: '200px', marginLeft: '-1px'}} src='/img/google-play-badge.png'/></a>
    </div>
  )
}

