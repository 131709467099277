
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { db, routineRepositoryDb } from "src/config/db";
import { IDrill } from "src/models/Drill";
import { PhysioItem, physioKey } from "src/models/Physio";
import { Sequence } from "src/models/Sequence";
import { IWorkout } from "src/models/Workout";
// import { DrillItem } from "./DrillList";

export function SearchComponent({searchInput, setSearching}: {searchInput?: (inputValue: string, inputOnChange: (value: string) => void) => any, setSearching?: (searching: boolean) => void}) {

  const [results, setResults] = useState<PhysioItem[]>([]);
  // const useStateFromParent = !!searchResults;

  const [searchParams, setSearchParams] = useSearchParams();
  const inputValue = searchParams.get('q') || '';

  useEffect(() => {
    const query = searchParams.get('q') || '';
    if (setSearching) { setSearching(!!inputValue); }
    if (query === '') { setResults([]); return };

    console.log("Search for: " + query);

    const regexp_query = new RegExp(query, 'i');
    function filterAndScoreDrill(drill: any) {
      if (regexp_query.test(drill.name)) {
        drill.score = 100;
        return true;
      } else if (regexp_query.test(drill.info)) {
        drill.score = 75;
        return true;
      // } else if (sequenceDeepSearch(drill.sequence, query))
      //   drill.score = 10;
      //   return true;
      } else {
        return false;
      }
    }

    function filterAndScoreWorkout(workout: any) {
      if (filterAndScoreDrill(workout)) {
        workout.score += 10;
        return true;
      } else if (workout.drills.some(filterAndScoreDrill)) {
        workout.score = 50;
        return true;
      } else {
        return false;
      }
    }

    // const rr_drills = routineRepositoryDb.drills.filter((drill) => {return filterAndScore(drill)}).limit(100)
    const item_list_promises = []
    item_list_promises.push(routineRepositoryDb.drills.filter(filterAndScoreDrill).limit(100).toArray())
    item_list_promises.push(routineRepositoryDb.workouts.filter(filterAndScoreWorkout).limit(100).toArray())

    item_list_promises.push(db.drills.filter(filterAndScoreDrill).limit(100).toArray())
    item_list_promises.push(db.workouts.filter(filterAndScoreWorkout).limit(100).toArray())

    Promise.all(item_list_promises).then((results) => {
      let items = results.flat(1)

      // items.forEach((item) => { if (item.lastAccessedAt) { (item as any).score += 1; } });
      // items.sort((a,b) => (b as any).score - (a as any).score); // Sort items in descending order based on score
      items.sort((ai, bi) => {
        const a = (ai as any).score; const b = (bi as any).score;

        if (a === b && (ai.lastAccessedAt || bi.lastAccessedAt)){ // sort recently accessed higher
          return (bi.lastAccessedAt?.getTime() || 0) - (ai.lastAccessedAt?.getTime() || 0);
        } else {
          return b - a;
        }
      });

      // Remove duplicates so that only the first one of specific id stays in the list.
      items = items.filter((item, index, self) => {
        return self.findIndex((i) => i.id === item.id) === index;
      });

      console.log("Query", query, "Found", items.length )
      setResults(items)
      // useStateFromParent ? setSearchResults!(items) : setResults(items);
    })
  }, [inputValue, searchParams, setSearching]);

  function inputOnChange(e: any) {
    console.log("Inside search inputOnChange", e.target.value)
    setSearchParams({q: e.target.value}, {replace: true})

    if (setSearching) { setSearching(!!e.target.value); }
  }

  return (
    <div className='search'>
      {searchInput ? searchInput(inputValue, inputOnChange) : <input type="text" className='form-control-lg my-4' placeholder="Search workouts and drills..." value={inputValue} onChange={inputOnChange} />}

      {/* {inputValue && results && results.length > 0 && */}
      {inputValue && results &&
        <div className="results">
          <div className="drill-list list-group mt-2">
            {/* {(useStateFromParent ? searchResults : results).map((item) => PhysioItem(item))} */}
            {/* {results.map((item) => PhysioItemView({physio: item}))} */}
            {results.map((item) => <PhysioItemView item={item} key={physioKey(item)}/>)}
          </div>
        </div>
      }
    </div>
  )
}


export function PhysioItemView({item}: {item: PhysioItem}) {
  return ('drills' in item) ? WorkoutItem(item) : DrillItem({drill: item});
}

function WorkoutItem(workout: IWorkout) {
  // const key = `workout_${workout.id}_${workout.lastAccessedAt ? 'db' : 'rr'}`
  return (
    <div className="item">
      <Link to={`/workouts/${workout.id}/${encodeURIComponent(workout.name)}`}
              className="">
        <h5><i className="bi-card-list"></i> {workout.name} (Workout)</h5>
        <span>
          { workout.drills.map((drill, index) => (
            <span key={`workout_${workout.id}_${index}`}>{drill.name}{index < workout.drills.length-1 && ", "}</span>
          ))}
        </span>
      </Link>
      <Link to={`/workouts/${workout.id}/edit`} className="edit-link"><i className="bi-gear-fill"></i></Link>
    </div>
  )
}

export function DrillItem({drill, link_relative='/'}: {drill: IDrill; link_relative?: string}) {
  // const is_routine_rep = ('last_accessed' in drill);
  // const key = `drill_${drill.id}_${drill.lastAccessedAt ? 'db' : 'rr'}`
  return (
    <div className="item">
      <Link to={`${link_relative}drills/${drill.id}/${link_relative === '/' ? encodeURIComponent(drill.name) : ''}`}
              className="">
        <h5>{drill.name}</h5>
        {/* {drill.lastAccessedAt ? 'LAST ACCESS': ''} */}

        <span>{new Sequence(drill.sequence).render()}</span>
      </Link>
      <Link to={`/drills/${drill.id}/edit`} className="edit-link"><i className="bi-gear-fill"></i></Link>
    </div>
  );
}

