import { IDrill } from "./Drill";
import { IWorkout } from "./Workout";

export type PhysioItem = IDrill | IWorkout;

export function physioKey(item: PhysioItem) {
  if ('drills' in item) {
    return `workout_${item.id}_${item.name}`;
  } else {
    return `drill_${item.id}_${item.name}`; // we use item.id as info about the position so we have to use name in the key
  }
}



