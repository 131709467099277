import { Outlet } from "react-router-dom";
import FooterNav from "./partials/FooterNav";


// type ContextType = { user: User | null };
// export function useWorkout() {
//   return useOutletContext<any>();
// }

export function WorkoutElement(){
  // const [workout, setWorkout] = React.useState<IWorkout>(dummyWorkout);

  return (
    <>
      <div id="workout-element" className="">
        {/* <Outlet context={[workout, setWorkout]}/> */}
        <Outlet/>
      </div>
    </>
  )
}

export function WorkoutElementContainer(){
  // const [workout, setWorkout] = useWorkout();

  return (
    <>
      <div className="container layout-container">
        {/* <Outlet context={[workout, setWorkout]}/> */}
        <Outlet/>
      </div>
      <FooterNav fixed />
    </>
  )
}

