import { dbResetAll } from "src/config/db";

export function console_log_for_non_test(...args: any[]) {
  if (process.env.NODE_ENV !== 'test') {
    console.log(...args);
  }
}

export async function loaderPreFilter(request: { url: string; }){
  if (request) {
    const req_params = new URL(request.url.toLowerCase()).searchParams;
    if (req_params.get('resetdb') === 'true'){
      console.log("Params resetDb=true, resetting db")
      await dbResetAll(); // Use the imported 'dbResetAll' function
    }
  }
}

