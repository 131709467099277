import { Capacitor } from '@capacitor/core';
import { Analytics } from './firebase';
import { LocalNotifications as CordovaLocalNotifications } from '@awesome-cordova-plugins/local-notifications/'; // https://github.com/powowbox/cordova-plugin-local-notification-12


// Called from Layout.useEffect once when app starts
export async function LocalNotificationSetup() {
  if (Capacitor.getPlatform() === 'android') {
    console.log("LocalNotificationSetup", Capacitor.getPlatform());
    // await LocalNotifications.removeAllListeners();
    // await LocalNotifications.removeAllDeliveredNotifications();
    // await LocalNotifications.addListener('localNotificationReceived', (notification) => { console.log("localNotificationReceived", notification) });
    // await LocalNotifications.addListener('localNotificationActionPerformed', (act) => { console.log("localNotificationActionPerformed", act.actionId, act.notification) });
    // await LocalNotifications.registerActionTypes({types: [{ id: "DrillActions", actions: [{ id: "prev", title: "⏪" }, { id: "stop", title: "⏹" }, { id: "nexd", title: "⏩" }] }]});

    CordovaLocalNotifications.requestPermission().then(
      (granted) => {
        console.log("Notifications request permissions:", granted)
        Analytics.logCustomEvent({name: 'notifications', event_category: 'permissions', event_label: granted});
        Analytics.logPageView({ page_location: `notification_permissions/${granted}`, page_title: granted });

        // add, trigger, click, clear, cancel, update, clearall, cancelall
        // CordovaLocalNotifications.on(event, scope);

        const events = ['add', 'trigger', 'click', 'clear', 'cancel', 'update', 'clearall', 'cancelall'];
        events.forEach(event => {
          CordovaLocalNotifications.on(event).subscribe(notification => {
            console.log('Notifications.on', event, notification, JSON.stringify(notification));
            const label = notification.title || "";
            Analytics.logCustomEvent({name: 'notifications', event_category: event, event_label: label});
          });
        });

      },
      (err) => {
        console.error("Notifications request permissions error:", err)
        Analytics.logCustomEvent({name: 'notifications', event_category: 'permissions', event_label: 'error'});
        Analytics.logPageView({ page_location: 'notification_permissions/error', page_title: 'error' });
      }
    );

    CordovaLocalNotifications.clearAll();
  }
}
