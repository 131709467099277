import { Step, IStep } from './Step';

export interface ISequence {
  reps: number;
  steps: ISequence[] | IStep;
  // steps: (ISequence|IStep)[] | IStep;
}

export interface ISequenceCompact {
  reps: number;
  steps: (ISequence | ISequenceCompact | IStep)[] | IStep;
}

export class Sequence {

  reps: number = 1;
  steps!: Sequence[] | Step; // | undefined = undefined;
  rest_steps_combined: number[] = [];
  unraveled: Step[] = [];

  constructor({ reps, steps }: ISequenceCompact = { reps: 1, steps: [] }) {
    this.reps = Number(reps);
    // this.steps = (steps.constructor === Array) ? steps.map((seq) => Sequence.deserialize(seq)) : Step.deserialize(steps as unknown as IStep);
    if (steps.constructor === Array) {
      this.steps = steps.map((seq) => Sequence.deserialize(('reps' in seq) ? seq : { reps: 1, steps: seq }))
    } else {
      this.steps = Step.deserialize(steps as IStep);
    }
    this.unravel();
  }

  static dummy(): Sequence {
    return new Sequence({ reps: 1, steps: { name: "Prepare", time: 1 } });
  }

  serialize(): ISequence {
    if (this.steps && this.steps.constructor === Step) {
      return { reps: this.reps, steps: this.steps.serialize() }
    } else if (this.steps && this.steps.constructor === Array) {
      // return {reps: this.reps, steps: (this.steps as Sequence[]).map((seq) => {return seq.serialize()})}
      return { reps: this.reps, steps: (this.steps as Sequence[]).map((seq) => { return seq.serialize() }) }
    } else {
      console.log("Sequence.serialize: this.steps is not a Step or Sequence[]:", this.steps)
      throw new Error('Sequence.serialize: this.steps is not a Step or Sequence[].')
    }
  }

  static serialize_compactor(iseq: ISequence): ISequenceCompact {
    function compact_helper(iseq: ISequenceCompact): ISequenceCompact | IStep | (ISequenceCompact | IStep)[] {
      if (iseq.reps === 1) {
        if (Array.isArray(iseq.steps)) {
          return iseq.steps.map((s) => { return ('reps' in s) ? compact_helper(s) : s }).flat()
        } else {
          return iseq.steps
        }
      } else {
        if (Array.isArray(iseq.steps)) {
          return { reps: iseq.reps, steps: iseq.steps.map((s) => { return ('reps' in s) ? compact_helper(s) : s }).flat() }
        } else {
          return { reps: iseq.reps, steps: iseq.steps }
        }
      }
    }

    let res = compact_helper(iseq);
    if (!('reps' in res)) {
      res = { reps: 1, steps: res }
    }
    return res as ISequence;
  }

  serialize_compact(): ISequenceCompact {
    return Sequence.serialize_compactor(this.serialize());
  }

  static deserialize(iseq: ISequenceCompact): Sequence {
    return new Sequence(iseq)
  }

  static unifyWithSerialize(iseq: ISequenceCompact): ISequence {
    return Sequence.deserialize(iseq).serialize();
  }

  static areEqual(a: ISequenceCompact|ISequence, b: ISequenceCompact|ISequence): boolean {
    // Make sure that objects with different order of keys are still the same
    function sortObject(obj: any): any {
      if (typeof obj !== 'object' || obj === null) { return obj; }

      if (Array.isArray(obj)) { return obj.map(sortObject); }

      // Sort objects and their nested objects
      const sortedKeys = Object.keys(obj).sort();
      const result: any = {};
      for (const key of sortedKeys) {
        result[key] = sortObject(obj[key]); // Recursively apply sorting
      }
      return result;
    }
    const jsonifySequence = (seq: ISequenceCompact|ISequence) => JSON.stringify(sortObject(Sequence.unifyWithSerialize(seq)));
    return jsonifySequence(a) === jsonifySequence(b)
  }


  getStep(position: number): Step {
    let step = this.unraveled[position]
    if (step === undefined) {
      throw new Error('Sequence.getStep: Out of Bounds. (pos:' + position + ', len:' + this.length + ')');
    }
    step.reset()
    return step
  }

  unravel() {
    this.unraveled = [];
    for (let i = 0; i < this.reps; i++) {
      if (Array.isArray(this.steps)) {
        this.unraveled.push(...this.steps.map((seq) => (seq.unraveled)).flat());
      } else { // if (this.steps.constructor === Step) {
        this.unraveled.push(this.steps);
      }
    }

    // for (let i = 0; i < this.unraveled.length-1; i++) {
    //   this.unraveled[i].nextStep = this.unraveled[i + 1];
    // }

    // Remove/Combine rests between rests
    for (let i = 0; i < this.unraveled.length - 1; i++) {
      if (this.unraveled[i].isRest() && this.unraveled[i + 1] && this.unraveled[i + 1].isRest()) {
        this.unraveled.splice(i, 1); // Remove the current element
        this.rest_steps_combined.push(i);
        i--; // Decrement the index to revisit the current position
      }
    }
  }

  get length(): number { // Length of the sequence, 1 if only one step
    return this.unraveled.length;
  }

  _render_internal(pos: number, current_step: Step|null): [string, boolean] {
    if (Array.isArray(this.steps)) {
      const seq_single_rep_length = this.length/this.reps

      // let travel_pos = pos;
      let travel_pos = pos % seq_single_rep_length;
      let current_step_found = false;

      let steps_info = this.steps.map((seq) => {
        let info = "";
        // info = `|p${pos}h${highlight_pos}l${this.length}sl${seq_single_rep_length}t${0}|`;
        // info = `|p${pos}l${this.length}sl${seq_single_rep_length}t${0}|`;

        const rend_int = seq._render_internal(travel_pos, current_step);
        info += rend_int[0];
        current_step_found = current_step_found || rend_int[1];

        travel_pos -= seq.length;
        return info;
      // }).join(" -> ");
      }).join(" ➙ ");

      // http://xahlee.info/comp/unicode_arrows.html ⇨ ➜ ➙
      // not in mobile 🡒 (sans serif)
      // https://icons.getbootstrap.com/icons/arrow-right/


      if (this.reps > 1) {
        if (current_step_found) {
          // let seq_rep = (pos < 0) ? 0 : ((pos > this.length) ? this.reps : (Math.floor(pos / seq_single_rep_length) + 1));
          // seq_rep = Math.min(seq_rep, this.reps); // Way to fix 3/2 render when having rest_steps_combined items
          let seq_rep = (Math.floor(pos / seq_single_rep_length)) + 1;
          steps_info = "[" + seq_rep + " / " + this.reps + ": " + steps_info + "]";
        } else {
          steps_info =  "[" + this.reps + " * " + steps_info + "]";
        }
      }
      return [steps_info, current_step_found];
    } else { // Only single prompt
      let str = this.steps.name;
      const current = (current_step && current_step === this.steps) as boolean;
      if (current) { str = "__" + str + "__" }

      if (this.reps > 1) {
        if (!current) {
          str = "[" + this.reps + " * " + str + "]";
        } else {
          str = "[" + (pos + 1) + " / " + this.reps + ": " + str + "]";
        }
      }

      return [str, current];
    }
  }

  render(pos = -1): string {
    const current_step = (pos >= 0 && pos < this.length) ? this.unraveled[pos] : null;
    let str = this._render_internal(pos, current_step)[0];
    if (str.startsWith('[') && str.endsWith(']')) {
      str = str.substring(1, str.length - 1);
    }
    return str;
  }
}


// export function sequenceDeepSearch(seq, regexp) {
//   let found = false;

//   function searchInside(seq) {
//       // Check if item is an object or array, and iterate over its properties
//       if (item && typeof item === 'object') {
//           for (const key in item) {
//               if (item.hasOwnProperty(key)) {
//                   const value = item[key];

//                   // Check if the key is 'name' and if the value includes the searchString
//                   if (key === 'name' && typeof value === 'string' && value.includes(searchString)) {
//                       found = true;
//                       return; // Stop searching if we found a match
//                   }

//                   // If the property is an object or array, search inside it
//                   if (typeof value === 'object') {
//                       searchInside(value);
//                       if (found) return; // Stop searching if a match was found in a nested object
//                   }
//               }
//           }
//       }
//   }

//   searchInside(obj);
//   return found;
// }

