
import { Link, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { db } from 'src/config/db';
import { ISequence } from "src/models/Sequence";
import 'src/stylesheets/DrillEdit.scss';
import { Workout } from 'src/models/Workout';
import { DrillForm } from './DrillEdit';


export function New(){
  const workout = useRouteLoaderData('workout_element') as Workout;
  const navigate = useNavigate();

  // const newFormDefaultValues = {name: "", sequence: Sequence.dummy().serialize()};
  const newFormDefaultValues = {
    name: "", sequence:
      { reps: 1, steps: [{ reps: 1, steps: { name: "Prepare", time: 5, color: 'gray' } },
                         { reps: 10, steps: { time: 5, sound: 'beep' }},
                         { reps: 1, steps: { name: "Rest", time: 45 }}] } as ISequence
  }

  function save(data: any) {
    console.log("Workout DrillFormEdit creating new drill", workout.id, data)
    workout.drills.push(data);
    db.workouts.update(workout.id!, workout).then(() => {
      console.log("WorkoutDrillEdit new created")
      navigate(`..`);
    })
  }

  function buttons(isDirty: boolean) {
    return <>
      <Link id='link_back' to={`..`} className={`btn ${isDirty ? 'btn-secondary' : 'btn-primary'}`}>Back to workout</Link>
    </>
  }

  return (
    <div className=""><h2>Editing Workout ({workout.id}) Drill </h2>
      <DrillForm type='new' drillValues={newFormDefaultValues} save={save} buttons={buttons} />
    </div>
  )
}


export function Edit(){
  const workout = useRouteLoaderData('workout_element') as Workout;
  // const params = useParams();
  // let { drillId } = useParams();
  const drillId = parseInt(useParams().drillId!, 10);

  const iDrill = workout.drills[drillId];
  const navigate = useNavigate();

  function save(data: any) {
    console.log("Workout DrillFormEdit saving", workout.id, drillId, data)
    workout.drills[drillId] = data;
    db.workouts.update(workout.id!, workout).then(() => {
      console.log("WorkoutDrillEdit saved", workout.id, drillId)
      navigate(`..`);
    })
  }

  function deleteDrill() {
    workout.drills.splice(drillId, 1);
    db.workouts.update(workout.id!, workout).then(() => {
      console.log("DrillFormEdit deleted", drillId)
      // navigate(`/workouts/${workout.id}`);
      navigate(`..`);
    })
  }

  function buttons(isDirty: boolean) {
    return <>
      <Link id='link_back' to={`..`} className={`btn ${isDirty ? 'btn-secondary' : 'btn-primary'}`}>Back to workout</Link>
      <button className="btn btn-secondary" onClick={(e) => { e.preventDefault(); deleteDrill() }}>Delete</button>
    </>
  }

  return (
    <div className=""><h2>Editing Workout ({workout.id}) Drill </h2>
      <DrillForm type='edit' drillValues={iDrill} save={save} buttons={buttons} />
    </div>
  )
}
