import { Link, useLocation, useRouteError } from "react-router-dom";
import { Analytics } from "src/config/firebase";
import FooterNav from "./partials/FooterNav";

// errorElement ... https://reactrouter.com/en/main/start/tutorial
export default function ErrorPage() {
  const error = useRouteError() as any;
  const location = useLocation();
  console.error("Error", error);

  // Analytics.logEvent({name: `error_${error.status}`, params: { message: error.message, page: location.pathname } });
  Analytics.logPageView({ page_title: `error_${error.status}`, page_location: location.pathname });
  Analytics.logException({ description: `${error.status}_${error.message}`, fatal: (error.status !== 404) });
  Analytics.logCustomEvent({ name: 'ErrorPage', event_category: error.status, event_label: `${error.status}_${error.message}`})

  return (<>
    <div id="error-page" className="container">
      <h1 className="my-4">Oops! <span className="fs-4 text-muted mx-4">({error.status})</span></h1>

      {error.status === 404 ?
            <p>Sorry, the page you were looking for could not be found.</p> :
            <p>Sorry, an unexpected error has occurred.</p>}

      <p>If you think this shouldn't happen. We appreciate feedback at admin@physiotimer.com . Let us know what you tried to do and what happened.</p>

      {error.statusText || error.message ?
        <p className="text-muted"><i>({error.statusText || error.message})</i></p>
        : '' }

      <p className="my-4"><Link className="btn btn-primary" to={`/`}>Go back to main page</Link></p>

    </div>
    <FooterNav fixed />
  </>);
}

