import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { ShowStoreLinkIfTargetAudience } from "src/utils/AppAdvert";

export default function Page() {
  return (
    <div className="common-page seo-page">

      <Helmet>
        <title>Maximize Athletic Performance: Agility and Balance Drills with PhysioTimer</title>
      </Helmet>

      <div className="title">
        <img src='/icons/icon.svg' alt="PhysioTimer logo"/>
        <h2>PhysioTimer for Speed, Agility and Balance Drills</h2>
      </div>

      <p className="mt-4 section">Welcome to PhysioTimer, the definitive tool for athletes, physiotherapists, coaches, and fitness enthusiasts aiming for a smart, personalized enhancement in training and rehabilitation. In this page, we will explain how you can utilize the app's randomized elements to engage in perturbation drills effectively.</p>

      <div className="row section">
        <div className="col-xl-7">
          <h2>Revolutionize Your Agility Drills</h2>

          <p>
            Traditional agility drills require a coach to provide randomized inputs, but with PhysioTimer, you can undertake these drills independently. Our app delivers dynamic cues and directions, allowing you to focus on enhancing your quickness, coordination, and reaction times without external assistance.
          </p>

          <p>
            The following drill example utilizes cones and the app's voice commands to guide your movements. You'll run to touch a cone upon each prompt and then return to the starting position.
          </p>

          <p>
            <Link to='/drills/41' className="btn btn-primary drill-btn">
              <img src='/icons/icon.svg' alt=""/>
              See an example of cone run.
            </Link><br/>
          </p>

        </div>

        <div className="col"><div className="d-none d-xl-block">
          <iframe className='youtube' title="Youtube video" src="https://www.youtube-nocookie.com/embed/vyvse9EtOH8" allow="encrypted-media" allowFullScreen></iframe>
          An example of traditional agility drill with someone yelling the instructions, you can use the app to do the same.

        </div></div>
      </div>

      <div className="row">
        <div className="col-xl-7">
          <div className="section">
            <h2>Enhanced Balance Drills</h2>

            <p>
              Balance training on a bosu ball is effective, but incorporating perturbations with PhysioTimer's randomized cues significantly increases efficiency. It's good if you have someone to give slight nudges while balancing but with PhysioTimer you can achieve similar results without external assistance.
            </p>

            <p>
              Following drill is designed to improve your stability. In this you use the voice cues to decide in which direction to tap with your foot. Requiring you to balance on one leg and react to the cues.
            </p>

            <p className="mb-4">
              <Link to='/drills/42' className="btn btn-primary drill-btn">
                <img src='/icons/icon.svg' alt=""/>
                Try Single Leg Balance with Random Tap
              </Link><br/>
            </p>

            <div className="d-xl-none">
              <iframe src="/balance_demo/index.html" title="Balance demo" className="balance-demo"></iframe>
            </div>
          </div>

          <div className="section">
            <h2>Customized Drill Complexity
              <span style={{fontWeight: 'bold'}} className="ps-3">(
              <span style={{ color: 'var(--bs-blue)' }}>↗</span>
              <span style={{ color: 'red' }}>↘</span>
              <span style={{ color: 'green' }}>↓</span>
              <span style={{ color: 'purple' }}>↙</span>
              <span style={{ color: 'cyan' }}>↖</span>
              <span style={{ color: 'orange' }}>↑</span>
              )</span>
            </h2>
            <p>
              Enhance the challenge by randomizing the directions, colors, or both. For an added level of difficulty, use color cues for performing opposite directions. For example, responding to red cues by moving in the opposite direction and green cues by moving towards, challenges your decision-making and reaction time. Direction cues can be seen visually from the phone or auditory via the app's voice functionality.
            </p>
          </div>
        </div>

        <div className="col"><div className="d-none d-xl-block">
            <iframe src="/balance_demo/index.html" title="Balance demo" className="balance-demo"></iframe>
        </div></div>
      </div>

      <div className="row section">
        <div className="col-xl-7">
          <h2>Additional Information</h2>

          <p>Key features of PhysioTimer also include user-friendly timers and effortless drill sharing. Explore more on our <Link to='/about'>About Page</Link>.</p>
          <p>Have ideas or need support? Connect with us at <a href='mailto:support@physiotimer.com'>support@physiotimer.com</a>.</p>
          <p>Don't wait to elevate your training. Visit our <Link to='/'>Home Page</Link> now to explore and create custom drills and timers that will transform your performance</p>

          <ShowStoreLinkIfTargetAudience />
        </div>
      </div>

    </div>
  );
}

