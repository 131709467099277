import { Prompt } from './Prompt';

export interface IStep {
  name?: string;
  time?: number;
  sound?: null | string;
  prompts?: string | string[];
  color?: string | string[];
}

export class Step {
  istep!: IStep;

  prompts!: Prompt[];
  time?: number;
  prompt: Prompt | null = null;
  color: string = 'gray';

  chosen!: number[];
  // nextStep: Step | null = null;

  constructor(istep: IStep) {
    this.istep = istep;
    if (this.istep.time) { this.istep.time = Number(this.istep.time) }

    if (this.istep.prompts || this.istep.name) {
      this.prompts = this.istep.prompts ? Prompt.getOrCreateByNames(this.istep.prompts) : Prompt.getOrCreateByNames(this.istep.name || '')
      this.chosen = Array(this.prompts.length).fill(0)
    }

    this.reset();
  }

  get name() {
    // return this.istep.name ? this.istep.name : (this.istep.time + 's');
    if (this.istep.name) {
      return this.istep.name
    } else {
      const t = this.istep.time!;
      if (t > 60 && t%60 === 0) {
        return (t/60) + 'min';
      } else {
        return t + 's';
      }
    }
  }

  isRest(): boolean {
    let name = this.name.toLowerCase();
    const arr = ['rest', 'cooldown', 'break', 'lepo'];
    // return this.time !== undefined && this.time >= 10 && arr.some(str => name.startsWith(str));
    return this.time !== undefined && arr.some(str => name.startsWith(str));
  }

  serialize(): IStep {
    // let res: IStep = {name: this.name, time: this._time, sound: this.sound, color: this._color};
    // if (this.prompts !== false){ res['prompts'] = this.prompts };
    return this.istep;
  }
  static deserialize(istep: IStep): Step {
    // let step = new Step()
    // step.istep = istep;
    // step.reset();
    // return step;
    return new Step(istep);
  }

  reset(): void {
    // if (this.istep.prompts || this.istep.name) {
    //   let prompts = this.istep.prompts ? Prompt.getOrCreateByNames(this.istep.prompts) : Prompt.getOrCreateByNames(this.istep.name || '')
    //   this.prompt = prompts[Math.floor(Math.random() * prompts.length)];
    // } else {
    //   this.prompt = null;
    // }
    if (this.prompts) {
      // this.prompt = this.prompts[Math.floor(Math.random() * this.prompts.length)];
      const min_chosen = Math.min(...this.chosen)
      const weights = this.chosen.map((i) => 1/(i-min_chosen+1))
      const idx = weightedRandomIndex(weights)
      this.chosen[idx] += 1;
      this.prompt = this.prompts[idx];
    }

    this.time = this.istep.time;

    if (Array.isArray(this.istep.color)) {
      this.color = this.istep.color[Math.floor(Math.random() * this.istep.color.length)];
    } else if (typeof this.istep.color === 'string') {
      this.color = this.istep.color;
    }
    if (!this.color.startsWith('#')) {
      this.color = colorToHex(this.color);
    }
  }
}

// https://stackoverflow.com/questions/43566019/how-to-choose-a-weighted-random-array-element-in-javascript
function weightedRandomIndex(weights: number[]) : number {
  let i;

  for (i = 1; i < weights.length; i++)
      weights[i] += weights[i - 1];

  let random = Math.random() * weights[weights.length - 1];

  for (i = 0; i < weights.length; i++)
      if (weights[i] > random)
          break;

  return i;
}

function colorToHex(colour: string): string {
  const colours = {
    "aliceblue": "#f0f8ff", "antiquewhite": "#faebd7", "aqua": "#00ffff", "aquamarine": "#7fffd4", "azure": "#f0ffff",
    "beige": "#f5f5dc", "bisque": "#ffe4c4", "black": "#000000", "blanchedalmond": "#ffebcd", "blue": "#0000ff", "blueviolet": "#8a2be2", "brown": "#a52a2a", "burlywood": "#deb887",
    "cadetblue": "#5f9ea0", "chartreuse": "#7fff00", "chocolate": "#d2691e", "coral": "#ff7f50", "cornflowerblue": "#6495ed", "cornsilk": "#fff8dc", "crimson": "#dc143c", "cyan": "#00ffff",
    "darkblue": "#00008b", "darkcyan": "#008b8b", "darkgoldenrod": "#b8860b", "darkgray": "#a9a9a9", "darkgreen": "#006400", "darkkhaki": "#bdb76b", "darkmagenta": "#8b008b", "darkolivegreen": "#556b2f",
    "darkorange": "#ff8c00", "darkorchid": "#9932cc", "darkred": "#8b0000", "darksalmon": "#e9967a", "darkseagreen": "#8fbc8f", "darkslateblue": "#483d8b", "darkslategray": "#2f4f4f", "darkturquoise": "#00ced1",
    "darkviolet": "#9400d3", "deeppink": "#ff1493", "deepskyblue": "#00bfff", "dimgray": "#696969", "dodgerblue": "#1e90ff",
    "firebrick": "#b22222", "floralwhite": "#fffaf0", "forestgreen": "#228b22", "fuchsia": "#ff00ff",
    "gainsboro": "#dcdcdc", "ghostwhite": "#f8f8ff", "gold": "#ffd700", "goldenrod": "#daa520", "gray": "#808080", "green": "#008000", "greenyellow": "#adff2f",
    "honeydew": "#f0fff0", "hotpink": "#ff69b4", "indianred ": "#cd5c5c", "indigo": "#4b0082", "ivory": "#fffff0", "khaki": "#f0e68c",
    "lavender": "#e6e6fa", "lavenderblush": "#fff0f5", "lawngreen": "#7cfc00", "lemonchiffon": "#fffacd", "lightblue": "#add8e6", "lightcoral": "#f08080", "lightcyan": "#e0ffff", "lightgoldenrodyellow": "#fafad2",
    "lightgrey": "#d3d3d3", "lightgreen": "#90ee90", "lightpink": "#ffb6c1", "lightsalmon": "#ffa07a", "lightseagreen": "#20b2aa", "lightskyblue": "#87cefa", "lightslategray": "#778899", "lightsteelblue": "#b0c4de",
    "lightyellow": "#ffffe0", "lime": "#00ff00", "limegreen": "#32cd32", "linen": "#faf0e6",
    "magenta": "#ff00ff", "maroon": "#800000", "mediumaquamarine": "#66cdaa", "mediumblue": "#0000cd", "mediumorchid": "#ba55d3", "mediumpurple": "#9370d8", "mediumseagreen": "#3cb371", "mediumslateblue": "#7b68ee",
    "mediumspringgreen": "#00fa9a", "mediumturquoise": "#48d1cc", "mediumvioletred": "#c71585", "midnightblue": "#191970", "mintcream": "#f5fffa", "mistyrose": "#ffe4e1", "moccasin": "#ffe4b5",
    "navajowhite": "#ffdead", "navy": "#000080", "oldlace": "#fdf5e6", "olive": "#808000", "olivedrab": "#6b8e23", "orange": "#ffa500", "orangered": "#ff4500", "orchid": "#da70d6",
    "palegoldenrod": "#eee8aa", "palegreen": "#98fb98", "paleturquoise": "#afeeee", "palevioletred": "#d87093", "papayawhip": "#ffefd5", "peachpuff": "#ffdab9", "peru": "#cd853f", "pink": "#ffc0cb", "plum": "#dda0dd", "powderblue": "#b0e0e6", "purple": "#800080",
    "rebeccapurple": "#663399", "red": "#ff0000", "rosybrown": "#bc8f8f", "royalblue": "#4169e1",
    "saddlebrown": "#8b4513", "salmon": "#fa8072", "sandybrown": "#f4a460", "seagreen": "#2e8b57", "seashell": "#fff5ee", "sienna": "#a0522d", "silver": "#c0c0c0", "skyblue": "#87ceeb", "slateblue": "#6a5acd", "slategray": "#708090", "snow": "#fffafa", "springgreen": "#00ff7f", "steelblue": "#4682b4",
    "tan": "#d2b48c", "teal": "#008080", "thistle": "#d8bfd8", "tomato": "#ff6347", "turquoise": "#40e0d0",
    "violet": "#ee82ee", "wheat": "#f5deb3", "white": "#ffffff", "whitesmoke": "#f5f5f5", "yellow": "#ffff00", "yellowgreen": "#9acd32"
  } as { [key: string]: string };

  colours['red'] = '#aa0000';
  // colours['blue'] = '#0000aa';
  colours['blue'] = 'var(--bs-blue)'; // see index.scss - brand color
  colours['green'] = 'var(--bs-green)';


  if (typeof colours[colour.toLowerCase()] != 'undefined')
    return colours[colour.toLowerCase()];

  return '#000000';
}

