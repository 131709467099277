/*eslint quote-props: ["error", "as-needed"]*/

import Dexie, { Table } from 'dexie';
import { IDrill } from 'src/models/Drill';
import { Sequence } from 'src/models/Sequence';
import { IWorkout } from 'src/models/Workout';
import { console_log_for_non_test } from 'src/utils/CommonUtils';

///////////////////////////////////////////////////////////

const routineRepositoryResetVersion = 1000; // Increment this when you want to reset routineRepositoryDb

export class DexieDb extends Dexie {
  drills!: Table<IDrill>; // This is for type system to realise that the collection exists
  workouts!: Table<IWorkout>;
  settings!: Table<{ name: string, value: string | number }>;

  constructor() {
    super('db_005');
    this.version(12).stores({
      drills: '++id, copiedFromId, name, lastAccessedAt', // only list indexed values
      workouts: '++id, copiedFromId, name, lastAccessedAt',
      settings: '&name'
    });
  }
}

export const db = new DexieDb();

// https://www.npmjs.com/package/@pvermeer/dexie-class-addon

// https://github.com/dexie/Dexie.js/issues/302
// https://dexie.org/docs/Table/Table.hook('reading')
// More complicated but newer way would be to do
// DBCORE like at https://stackoverflow.com/questions/66798726/ensure-data-types-in-dexie-js-fields
// table.hook('reading', obj => ClassConstructor.call(obj));
// db.drills.hook('reading', function (obj) {
//   obj.sequences = new SequenceList(obj.sequences as unknown as Array<any>);
//   // You may return another object or modify existing object.
// });

// db.drills.hook('reading', function (obj) {
//   obj.sequences = new SequenceList(obj.sequences as unknown as Array<any>);
//   // You may return another object or modify existing object.
// });

function check_seq(seq: any) {
  return Sequence.deserialize(seq).serialize();
}

db.drills.hook("creating", (_primKey, obj: IDrill, _trans) => { obj.sequence = check_seq(obj.sequence); });
db.drills.hook("updating", (mods, _primKey, _obj, _trans) => {
  // https://fettblog.eu/typescript-hasownproperty/ grah need to use 'in' instead of hasOwn
  // if (Object.hasOwn(mods, 'sequence') && mods.sequence) { return {sequence: check_seq(mods.sequence)} }
  if ('sequence' in mods) { return { sequence: check_seq(mods.sequence) } }
});


async function dbReset() {
  await db.drills.clear();
  await db.workouts.clear();
  await db.settings.clear();

  // Reset index to specific value, helps to distinct from routineRepositoryDb
  const idx = 100000;
  db.drills.put({id: idx} as any).then(() => { db.drills.delete(idx) });
  db.workouts.put({id: idx} as any).then(() => { db.workouts.delete(idx) });
}

if (process.env.NODE_ENV !== 'test') { // Resetting the index to make debugging easier.
  db.drills.count().then((count) => {
    if (count === 0) { dbReset(); }
  });
}

///////////////////////////////////////////////////////////
// RoutineRepository

export class RoutineRepositoryDb extends Dexie {
  drills!: Table<IDrill>; // This is for type system to realise that the collection exists
  // workouts!: Table<IWorkoutCompact>;
  workouts!: Table<IWorkout>;
  settings!: Table<{ name: string, value: string | number }>;

  constructor() {
    super('routine_repository_005');
    this.version(12).stores({
      drills: '++id, name, copiedFromId',
      workouts: '++id, name, copiedFromId',
      settings: '&name'
    });
  }
}

export const routineRepositoryDb = new RoutineRepositoryDb();
routineRepositoryDb.drills.hook("creating", (_primKey, obj: IDrill, _trans) => { obj.sequence = check_seq(obj.sequence); });

function parseFilesToJsons(context: any, test_files: undefined | any[]){
  const drills: any[] = [];

  let files: any;
  let keys: any;

  if (process.env.NODE_ENV !== 'test') {
    // files = require.context(dir, false, /\.json$/)
    files = context
    keys = files.keys()
  } else {
    // if(test_files!.length === 0) { throw new Error("Db need some files specified for test run") }
    // let data = [{ id: 11, name: "30 sec timer", sequence: check_seq({ reps: "100", steps: [{ reps: "1", steps: { name: "", time: "30", color: ["green"], sound: "beep" } }, { reps: "1", steps: { name: "", time: "30", color: ["blue"], sound: "beep" } }] }) }] as any as IDrill[];

    const fs = require('fs');
    const files_test: Record<string, any> = {};

    for (let i = 0; i < test_files!.length; i++) {
      let file_name = test_files![i];
      const data_raw = fs.readFileSync(file_name, 'utf8');
      files_test[file_name.replace(/db\/(drills|workouts\w*)\//, './')] = JSON.parse(data_raw);
    }

    keys = Object.keys(files_test)
    files = (key: any) => {return files_test[key]};
  }

  console_log_for_non_test("Db loading drills json files", keys);

  keys.forEach((key: string) => {
    const id = key.replace('./', '').replace('.json', '').split("_")[0];
    const idrill = files(key);
    idrill.id = parseInt(id);
    drills.push(idrill);
  })

  return drills;
}

export async function routineRepositoryDbReset() {
  await routineRepositoryDb.drills.clear();
  await routineRepositoryDb.workouts.clear();
  await routineRepositoryDb.settings.clear();

  let drill_test_jsons = ["db/drills/20_basic_random_arrows.json", "db/drills/21_left-right_green-red.json"];
  let drills = parseFilesToJsons(((process.env.NODE_ENV !== 'test') ? require.context('../../db/drills', false, /\.json$/) : null), drill_test_jsons);
  drills = drills.map((idrill: IDrill) => {
    idrill.sequence = check_seq(idrill.sequence);
    // console_log_for_non_test("Db drill", idrill.id, idrill.name, idrill);
    return idrill;
  });
  await routineRepositoryDb.drills.bulkPut(drills);


  // WORKOUTS NOT SUPPORTED YET
  if (process.env.NODE_ENV !== 'production') {
    let workouts_test_jsons = ["db/workouts_test/100_basic_workout.json"];
    let workouts = parseFilesToJsons(((process.env.NODE_ENV !== 'test') ? require.context('../../db/workouts', false, /\.json$/) : null), workouts_test_jsons);
    // Replace drill_id's with drill objects. These are got from routineRepositoryDb.drills
    await Promise.all(
      workouts.map(async (workout) => {
        const fixedDrills = await Promise.all(
          workout.drills.map(async (drill_id_etc: string | string[]) => {
            const [drill_id, drill_name_addition] = (drill_id_etc instanceof Array) ? [drill_id_etc[0], drill_id_etc[1]] : [drill_id_etc, ''];
            const drill = await routineRepositoryDb.drills.get(parseInt(drill_id));
            console.assert(drill !== undefined, `DB Workout (${workout.id}) references nonexisting drill (${drill_id})`);
            if (drill && drill_name_addition) {
              drill.name += ` (${drill_name_addition})`;
            }
            return drill;
          })
        );
        workout.drills = fixedDrills;
        // console.log("Workout drills fixed for", workout.id, workout.name, workout.drills);
      })
    );
    await routineRepositoryDb.workouts.bulkPut(workouts);
  }


  await routineRepositoryDb.settings.put({ name: 'routineRepositoryResetVersion', value: routineRepositoryResetVersion });
}

if (process.env.NODE_ENV !== 'test') {
  // routineRepositoryDb.drills.count().then((count) => {
  //   if (count === 0) {
  //     routineRepositoryDbReset();
  //   } else {
  routineRepositoryDb.settings.get({ name: 'routineRepositoryResetVersion'}).then((version) => {
    if (!version || version.value !== routineRepositoryResetVersion) {
      console_log_for_non_test("Routine repository db reset version changed. Resetting. New version:", routineRepositoryResetVersion, ", old version:", version?.value || "none");
      routineRepositoryDbReset();
    }
  });
}

export async function dbResetAll() {
  console_log_for_non_test("Resetting all db's")
  await routineRepositoryDbReset();
  await dbReset();
}


